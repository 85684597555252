<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <change-timezone-modal v-if="userUuid" />
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useCssVar, useWindowSize } from '@vueuse/core'

import store, { ns } from '@/store'
import { PRESENCE_STATE_MUTATIONS, PresenceStore } from '@/store/presence'
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue'
import { UserChannel } from '@/realtime/channels/private/userChannel'
import { ChannelEvents } from '@/realtime/channelEvents'
import { ADMIN_STATE_GETTERS, ADMIN_STATE_MUTATIONS, AdminStore } from '@/store/admin'
import { OrganisationPlanChannel } from '@/realtime/channels/cache/private/organisationPlanChannel'
import { AdminApiService } from '@/service/api/admin/v1/api'
import OrganisationMixin from '@/mixin/organisation'
import EventBus from './mixin/eventBus'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    ChangeTimezoneModal,

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  mixins: [EventBus, OrganisationMixin],
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  data() {
    return {
      privateUserChannel: null,
      privateCacheOrganisationPlanChannel: null,
      changeOrganisationSwal: null,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    userUuid() {
      return this.$store.state.admin.user.uuid
    },
    currentOrganisationUuid() {
      return this.$store.state.admin.user.currentOrganisationUuid
    },
  },
  watch: {
    userUuid() {
      this.handlePrivateUserChannel()
    },
    currentOrganisationUuid() {
      this.handlePrivateCacheOrganisationPlanChannel()
    },
  },
  mounted() {
    this.configureRollbar()
    this.handlePresenceChannel()
    this.handleTimeAgoMomentJsRealtime()
    this.handlePrivateUserChannel()
    this.handlePrivateCacheOrganisationPlanChannel()
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  destroyed() {
    this.$appPresenceChannel.unsubscribe()
  },
  methods: {
    configureRollbar() {
      if (this.$rollbar) {
        this.$rollbar.configure({
          payload: {
            person: {
              id: this.$store.state.admin.user.uuid,
              username: this.$store.state.admin.user.email,
              email: this.$store.state.admin.user.email,
              firstName: this.$store.state.admin.user.firstName,
              lastName: this.$store.state.admin.user.lastName,
            },
          },
        })
      }
    },
    handlePrivateCacheOrganisationPlanChannel() {
      const currentOrganisationPlan = this.$store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.CURRENT_ORGANISATION_PLAN)]
      if (this.currentOrganisationUuid) {
        this.privateCacheOrganisationPlanChannel = new OrganisationPlanChannel(this.currentOrganisationUuid)

        this.privateCacheOrganisationPlanChannel.subscribe()
        this.privateCacheOrganisationPlanChannel.bind(ChannelEvents.PUSHER_CACHE_MISS, async () => {
          const adminApiService = new AdminApiService(this.$store)
          const response = await adminApiService.getOrganisationPricingPlan(this.currentOrganisationUuid, true)
          if (response.isSuccess()) {
            this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_ORGANISATION_PLAN), response.data)
            if (currentOrganisationPlan.id !== response.data.id) {
              this.$emitEvent(ChannelEvents.PLAN_CHANGED)
            }
          }
        })

        this.privateCacheOrganisationPlanChannel.bind(ChannelEvents.PLAN_CHANGED, data => {
          this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_ORGANISATION_PLAN), data.plan)
          if (currentOrganisationPlan.id !== data.plan.id) {
            this.$emitEvent(ChannelEvents.PLAN_CHANGED)
          }
        })
      }
    },
    handlePrivateUserChannel() {
      if (this.userUuid !== undefined && this.userUuid !== null) {
        this.privateUserChannel = new UserChannel(this.userUuid)

        this.privateUserChannel.subscribe()
        this.privateUserChannel.bind(ChannelEvents.USER_CHANGED, async data => {
          const currentOrganisationUuid = this.$store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.CURRENT_ORGANISATION_UUID)]

          if (!currentOrganisationUuid) {
            await this.changeOrganisation(data.user.selectedUserOrganisation.organisation.uuid, false)
            return
          }

          if (currentOrganisationUuid !== data.user.selectedUserOrganisation.organisation.uuid) {
            this.changeOrganisationSwal = this.$swal.mixin({
              title: this.$t('swals.areYouSureToChangeOrganisation.title'),
              text: this.$t('swals.areYouSureToChangeOrganisation.description', { selectedOrganisation: data.user.selectedUserOrganisation.organisation.name }).toString(),
              icon: 'warning',
              showDenyButton: true,
              confirmButtonText: this.$t('common.buttons.yes').toString(),
              denyButtonText: this.$t('common.buttons.no').toString(),
              denyButtonColor: '#75757',
              reverseButtons: true,
              allowOutsideClick: false,
            })

            this.changeOrganisationSwal
              .fire()
              .then(async result => {
                if (result.isConfirmed) {
                  await this.changeOrganisation(data.user.selectedUserOrganisation.organisation.uuid, true, data.user.selectedUserOrganisation)
                } else if (result.isDenied) {
                  const adminApiService = new AdminApiService(this.$store)
                  await adminApiService.switchUserOrganisationByRequestUserAndOrganisationUuid(currentOrganisationUuid)
                }
              })
          } else {
            if (this.changeOrganisationSwal) {
              this.changeOrganisationSwal.close()
            }
            this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_USER_ACCOUNT), data.user)
          }
        })
      }
    },
    handlePresenceChannel() {
      this.$appPresenceChannel.subscribe()

      try {
        this.$appPresenceChannel.bind(ChannelEvents.PUSHER_SUBSCRIPTION_SUCCEEDED, members => {
          members.each(member => {
            const userInfo = member.info
            this.$store.commit(ns(PresenceStore.NS, PRESENCE_STATE_MUTATIONS.SET_USER), userInfo)
          })
        })
      } catch (e) {
        this.$store.commit(ns(PresenceStore.NS, PRESENCE_STATE_MUTATIONS.CLEAR_USERS))
      }

      // when a new user logs in / online
      this.$appPresenceChannel.bind(ChannelEvents.PUSHER_MEMBER_ADDED, member => {
        const userInfo = member.info
        this.$store.commit(ns(PresenceStore.NS, PRESENCE_STATE_MUTATIONS.SET_USER), userInfo)
      })

      // when a user logs out / offline
      this.$appPresenceChannel.bind(ChannelEvents.PUSHER_MEMBER_REMOVED, member => {
        const userInfo = member.info
        this.$store.commit(ns(PresenceStore.NS, PRESENCE_STATE_MUTATIONS.REMOVE_USER), userInfo)
      })
    },
    handleTimeAgoMomentJsRealtime() {
      setInterval(() => {
        this.$emitEvent('update-time-ago-moment-js')
      }, 60_000)
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
