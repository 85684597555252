export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/auth/login/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'auth',
        },
    },
    {
        path: '/logout',
        name: 'auth-logout',
        component: () => import('@/views/auth/Logout.vue'),
        meta: {
            layout: 'full',
            resource: 'auth',
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/auth/login/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'auth',
        },
    },
    {
        path: '/register',
        name: 'auth-register',
        component: () => import('@/views/auth/register/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password/:token',
        name: 'auth-reset-password',
        component: () => import('@/views/auth/login/ResetPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'auth',
        },
    },
];
