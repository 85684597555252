// eslint-disable-next-line import/no-cycle
import store, { ns } from '@/store';
// eslint-disable-next-line import/no-cycle
import { ADMIN_STATE_GETTERS, AdminStore } from '@/store/admin';
import Ability from '@/libs/acl/ability';
export const isUserLoggedIn = () => store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.USER_LOGGED_IN)];
export const bindAbilities = (abilities) => {
    Ability.update(abilities);
};
export const DEFAULT_ABILITIES = {
    USER_LOGGED_IN: {
        subject: 'user',
        action: 'logged-in',
    },
};
