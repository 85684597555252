// @ts-ignore
import { $themeConfig } from '@themeConfig';
export class VerticalMenuStore {
    constructor() {
        this.namespaced = true;
        this.state = {
            isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
        };
        this.getters = {};
        this.mutations = {
            UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
                state.isVerticalMenuCollapsed = val;
            },
        };
        this.actions = {};
    }
}
