import { DefaultErrorHandler, HttpClientWrapper, SuccessApiOperationResult } from '@/service/api/client';
import { BaseApiService } from '@/service/api/base/baseApiService';
import axios from 'axios';
const CONTENT_ENDPOINT = `${process.env.VUE_APP_API_URL}/api/content-api/v1`;
const contentApi = (store) => HttpClientWrapper.defaultInstance(store, { baseUrl: CONTENT_ENDPOINT });
// eslint-disable-next-line import/prefer-default-export
export class ContentApiService extends BaseApiService {
    // eslint-disable-next-line no-unused-vars,no-empty-function,no-useless-constructor
    constructor(store) {
        super();
        this.store = store;
    }
    async getRegions(pageQuery, queryParams, filterQueryParams) {
        try {
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get('/regions', { params: { ...pageQuery, ...queryParams, ...filterQueryParams } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationRegions(orgUuid, pageQuery, queryParams, filterQueryParams) {
        const functionName = 'getOrganisationRegions';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/regions`, {
                params: { ...pageQuery, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getWines(orgUuid, pageQuery, queryParams, filterQueryParams) {
        const functionName = 'getWines';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/wines`, {
                params: { ...pageQuery, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getWineries(orgUuid, pageQuery, queryParams, filterQueryParams) {
        const functionName = 'getWineries';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/wineries`, {
                params: { ...pageQuery, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getVintageProductsSizeUnits(orgUuid, activeOffers, filterQueryParams) {
        const functionName = 'getVintageProductsSizeUnits';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintage-products-size-units`, {
                params: { activeOffers, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getVintageYears(orgUuid, activeOffers, filterQueryParams) {
        const functionName = 'getVintageYears';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintages-years`, {
                params: { activeOffers, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getCountries(orgUuid, pageQuery, queryParams, filterQueryParams) {
        const functionName = 'getCountries';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/countries`, {
                params: { ...pageQuery, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationWineTypes(orgUuid, queryParams, filterQueryParams) {
        const functionName = 'getOrganisationWineTypes';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/wine-types`, {
                params: { ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getWineTypes() {
        try {
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get('/wine-types');
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSuppliers(orgUuid, pageQuery, queryParams, filterQueryParams) {
        const functionName = 'getOrganisationSuppliers';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/suppliers`, {
                params: { ...pageQuery, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploads(orgUuid, pageQuery, queryParams, filterQueryParams) {
        const functionName = 'getOrganisationUploads';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads`, {
                params: { ...pageQuery, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationVintageProduct(orgUuid, vintageProductUuid) {
        try {
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintage-products/${vintageProductUuid}`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationVintageProductAutocomplete(orgUuid, search) {
        try {
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintage-products/autocomplete`, {
                params: {
                    search,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getVintageProducts(vintageProductUuids, pageQuery) {
        try {
            const httpClient = contentApi(this.store).client();
            const mappedString = vintageProductUuids.map(uuid => `uuid[in][]=${uuid}`).join('&');
            const response = await httpClient.get(`/vintage-products?${mappedString}`, {
                params: { ...pageQuery },
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationVintageVintageProducts(orgUuid, vintageUuid) {
        try {
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintages/${vintageUuid}/vintage-products`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationWinesVintages(orgUuid, wineUuid) {
        try {
            const httpClient = contentApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/wines/${wineUuid}/vintages`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
}
