import { DEFAULT_ABILITIES } from '@/helpers/auth';
export default [
    {
        path: '/organisations/register',
        name: 'register-organisation',
        component: () => import('@/views/account/organisation/Register.vue'),
        meta: {
            resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
            action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
            pageTitle: 'pageTitles.organisations.register',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisation.register.organisation',
                },
                {
                    text: 'breadcrumbs.organisation.register.register',
                    active: true,
                },
            ],
        },
    },
];
