import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/settings/sales',
        name: 'sales-settings',
        component: () => import('@/views/organisation/settings/Sales.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.MARGIN__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.MARGIN__VIEW),
            pageTitle: 'pageTitles.settings.sales',
            breadcrumb: [
                {
                    text: 'breadcrumbs.sales.settings',
                },
                {
                    text: 'breadcrumbs.sales.sales',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
