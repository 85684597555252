import { $themeBreakpoints } from '@themeConfig';
export class AppStore {
    constructor() {
        this.namespaced = true;
        this.state = {
            windowWidth: 0,
            shallShowOverlay: false
        };
        this.getters = {
            currentBreakPoint: (state) => {
                const { windowWidth } = state;
                if (windowWidth >= $themeBreakpoints.xl)
                    return 'xl';
                if (windowWidth >= $themeBreakpoints.lg)
                    return 'lg';
                if (windowWidth >= $themeBreakpoints.md)
                    return 'md';
                if (windowWidth >= $themeBreakpoints.sm)
                    return 'sm';
                return 'xs';
            },
        };
        this.mutations = {
            UPDATE_WINDOW_WIDTH(state, val) {
                state.windowWidth = val;
            },
            TOGGLE_OVERLAY(state, val) {
                state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
            },
        };
        this.actions = {};
    }
}
