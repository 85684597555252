import { DefaultErrorHandler, HttpClientWrapper, SuccessApiOperationResult } from '@/service/api/client';
const MAPPED_EXTERNAL_1_ENDPOINT = `${process.env.VUE_APP_API_URL}/api/mapped-external-1-api/v1`;
// eslint-disable-next-line import/prefer-default-export
export const mappedExternal1Api = (store) => HttpClientWrapper.defaultInstance(store, { baseUrl: MAPPED_EXTERNAL_1_ENDPOINT });
// eslint-disable-next-line import/prefer-default-export
export class MappedExternal1ApiService {
    // eslint-disable-next-line no-unused-vars,no-empty-function,no-useless-constructor
    constructor(store) {
        this.store = store;
    }
    async searchAutocomplete(search, regionId, wineryId, typeId) {
        try {
            const httpClient = mappedExternal1Api(this.store).client();
            const response = await httpClient.get('/Wine/autocomplete', {
                params: {
                    search,
                    regionId,
                    wineryId,
                    typeId,
                },
            });
            return Promise.resolve(response.data.data);
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getWineries(search) {
        try {
            const httpClient = mappedExternal1Api(this.store).client();
            const response = await httpClient.get('/Winery', {
                params: {
                    search,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
}
