import { TimezoneEnum } from '@vini-wine/core-enums';
export const identifier = (timezone) => {
    switch (timezone) {
        case TimezoneEnum.AFRICA_ABIDJAN: return 'Africa/Abidjan';
        case TimezoneEnum.AFRICA_ACCRA: return 'Africa/Accra';
        case TimezoneEnum.AFRICA_ADDIS_ABABA: return 'Africa/Addis_Ababa';
        case TimezoneEnum.AFRICA_ALGIERS: return 'Africa/Algiers';
        case TimezoneEnum.AFRICA_ASMARA: return 'Africa/Asmara';
        case TimezoneEnum.AFRICA_BAMAKO: return 'Africa/Bamako';
        case TimezoneEnum.AFRICA_BANGUI: return 'Africa/Bangui';
        case TimezoneEnum.AFRICA_BANJUL: return 'Africa/Banjul';
        case TimezoneEnum.AFRICA_BISSAU: return 'Africa/Bissau';
        case TimezoneEnum.AFRICA_BLANTYRE: return 'Africa/Blantyre';
        case TimezoneEnum.AFRICA_BRAZZAVILLE: return 'Africa/Brazzaville';
        case TimezoneEnum.AFRICA_BUJUMBURA: return 'Africa/Bujumbura';
        case TimezoneEnum.AFRICA_CAIRO: return 'Africa/Cairo';
        case TimezoneEnum.AFRICA_CASABLANCA: return 'Africa/Casablanca';
        case TimezoneEnum.AFRICA_CEUTA: return 'Africa/Ceuta';
        case TimezoneEnum.AFRICA_CONAKRY: return 'Africa/Conakry';
        case TimezoneEnum.AFRICA_DAKAR: return 'Africa/Dakar';
        case TimezoneEnum.AFRICA_DAR_ES_SALAAM: return 'Africa/Dar_es_Salaam';
        case TimezoneEnum.AFRICA_DJIBOUTI: return 'Africa/Djibouti';
        case TimezoneEnum.AFRICA_DOUALA: return 'Africa/Douala';
        case TimezoneEnum.AFRICA_EL_AAIUN: return 'Africa/El_Aaiun';
        case TimezoneEnum.AFRICA_FREETOWN: return 'Africa/Freetown';
        case TimezoneEnum.AFRICA_GABORONE: return 'Africa/Gaborone';
        case TimezoneEnum.AFRICA_HARARE: return 'Africa/Harare';
        case TimezoneEnum.AFRICA_JOHANNESBURG: return 'Africa/Johannesburg';
        case TimezoneEnum.AFRICA_JUBA: return 'Africa/Juba';
        case TimezoneEnum.AFRICA_KAMPALA: return 'Africa/Kampala';
        case TimezoneEnum.AFRICA_KHARTOUM: return 'Africa/Khartoum';
        case TimezoneEnum.AFRICA_KIGALI: return 'Africa/Kigali';
        case TimezoneEnum.AFRICA_KINSHASA: return 'Africa/Kinshasa';
        case TimezoneEnum.AFRICA_LAGOS: return 'Africa/Lagos';
        case TimezoneEnum.AFRICA_LIBREVILLE: return 'Africa/Libreville';
        case TimezoneEnum.AFRICA_LOME: return 'Africa/Lome';
        case TimezoneEnum.AFRICA_LUANDA: return 'Africa/Luanda';
        case TimezoneEnum.AFRICA_LUBUMBASHI: return 'Africa/Lubumbashi';
        case TimezoneEnum.AFRICA_LUSAKA: return 'Africa/Lusaka';
        case TimezoneEnum.AFRICA_MALABO: return 'Africa/Malabo';
        case TimezoneEnum.AFRICA_MAPUTO: return 'Africa/Maputo';
        case TimezoneEnum.AFRICA_MASERU: return 'Africa/Maseru';
        case TimezoneEnum.AFRICA_MBABANE: return 'Africa/Mbabane';
        case TimezoneEnum.AFRICA_MOGADISHU: return 'Africa/Mogadishu';
        case TimezoneEnum.AFRICA_MONROVIA: return 'Africa/Monrovia';
        case TimezoneEnum.AFRICA_NAIROBI: return 'Africa/Nairobi';
        case TimezoneEnum.AFRICA_NDJAMENA: return 'Africa/Ndjamena';
        case TimezoneEnum.AFRICA_NIAMEY: return 'Africa/Niamey';
        case TimezoneEnum.AFRICA_NOUAKCHOTT: return 'Africa/Nouakchott';
        case TimezoneEnum.AFRICA_OUAGADOUGOU: return 'Africa/Ouagadougou';
        case TimezoneEnum.AFRICA_PORTO_NOVO: return 'Africa/Porto-Novo';
        case TimezoneEnum.AFRICA_SAO_TOME: return 'Africa/Sao_Tome';
        case TimezoneEnum.AFRICA_TRIPOLI: return 'Africa/Tripoli';
        case TimezoneEnum.AFRICA_TUNIS: return 'Africa/Tunis';
        case TimezoneEnum.AFRICA_WINDHOEK: return 'Africa/Windhoek';
        case TimezoneEnum.AMERICA_ADAK: return 'America/Adak';
        case TimezoneEnum.AMERICA_ANCHORAGE: return 'America/Anchorage';
        case TimezoneEnum.AMERICA_ANGUILLA: return 'America/Anguilla';
        case TimezoneEnum.AMERICA_ANTIGUA: return 'America/Antigua';
        case TimezoneEnum.AMERICA_ARAGUAINA: return 'America/Araguaina';
        case TimezoneEnum.AMERICA_ARGENTINA_BUENOS_AIRES: return 'America/Argentina/Buenos_Aires';
        case TimezoneEnum.AMERICA_ARGENTINA_CATAMARCA: return 'America/Argentina/Catamarca';
        case TimezoneEnum.AMERICA_ARGENTINA_CORDOBA: return 'America/Argentina/Cordoba';
        case TimezoneEnum.AMERICA_ARGENTINA_JUJUY: return 'America/Argentina/Jujuy';
        case TimezoneEnum.AMERICA_ARGENTINA_LA_RIOJA: return 'America/Argentina/La_Rioja';
        case TimezoneEnum.AMERICA_ARGENTINA_MENDOZA: return 'America/Argentina/Mendoza';
        case TimezoneEnum.AMERICA_ARGENTINA_RIO_GALLEGOS: return 'America/Argentina/Rio_Gallegos';
        case TimezoneEnum.AMERICA_ARGENTINA_SALTA: return 'America/Argentina/Salta';
        case TimezoneEnum.AMERICA_ARGENTINA_SAN_JUAN: return 'America/Argentina/San_Juan';
        case TimezoneEnum.AMERICA_ARGENTINA_SAN_LUIS: return 'America/Argentina/San_Luis';
        case TimezoneEnum.AMERICA_ARGENTINA_TUCUMAN: return 'America/Argentina/Tucuman';
        case TimezoneEnum.AMERICA_ARGENTINA_USHUAIA: return 'America/Argentina/Ushuaia';
        case TimezoneEnum.AMERICA_ARUBA: return 'America/Aruba';
        case TimezoneEnum.AMERICA_ASUNCION: return 'America/Asuncion';
        case TimezoneEnum.AMERICA_ATIKOKAN: return 'America/Atikokan';
        case TimezoneEnum.AMERICA_BAHIA: return 'America/Bahia';
        case TimezoneEnum.AMERICA_BAHIA_BANDERAS: return 'America/Bahia_Banderas';
        case TimezoneEnum.AMERICA_BARBADOS: return 'America/Barbados';
        case TimezoneEnum.AMERICA_BELEM: return 'America/Belem';
        case TimezoneEnum.AMERICA_BELIZE: return 'America/Belize';
        case TimezoneEnum.AMERICA_BLANC_SABLON: return 'America/Blanc-Sablon';
        case TimezoneEnum.AMERICA_BOA_VISTA: return 'America/Boa_Vista';
        case TimezoneEnum.AMERICA_BOGOTA: return 'America/Bogota';
        case TimezoneEnum.AMERICA_BOISE: return 'America/Boise';
        case TimezoneEnum.AMERICA_CAMBRIDGE_BAY: return 'America/Cambridge_Bay';
        case TimezoneEnum.AMERICA_CAMPO_GRANDE: return 'America/Campo_Grande';
        case TimezoneEnum.AMERICA_CANCUN: return 'America/Cancun';
        case TimezoneEnum.AMERICA_CARACAS: return 'America/Caracas';
        case TimezoneEnum.AMERICA_CAYENNE: return 'America/Cayenne';
        case TimezoneEnum.AMERICA_CAYMAN: return 'America/Cayman';
        case TimezoneEnum.AMERICA_CHICAGO: return 'America/Chicago';
        case TimezoneEnum.AMERICA_CHIHUAHUA: return 'America/Chihuahua';
        case TimezoneEnum.AMERICA_CIUDAD_JUAREZ: return 'America/Ciudad_Juarez';
        case TimezoneEnum.AMERICA_COSTA_RICA: return 'America/Costa_Rica';
        case TimezoneEnum.AMERICA_CRESTON: return 'America/Creston';
        case TimezoneEnum.AMERICA_CUIABA: return 'America/Cuiaba';
        case TimezoneEnum.AMERICA_CURACAO: return 'America/Curacao';
        case TimezoneEnum.AMERICA_DANMARKSHAVN: return 'America/Danmarkshavn';
        case TimezoneEnum.AMERICA_DAWSON: return 'America/Dawson';
        case TimezoneEnum.AMERICA_DAWSON_CREEK: return 'America/Dawson_Creek';
        case TimezoneEnum.AMERICA_DENVER: return 'America/Denver';
        case TimezoneEnum.AMERICA_DETROIT: return 'America/Detroit';
        case TimezoneEnum.AMERICA_DOMINICA: return 'America/Dominica';
        case TimezoneEnum.AMERICA_EDMONTON: return 'America/Edmonton';
        case TimezoneEnum.AMERICA_EIRUNEPE: return 'America/Eirunepe';
        case TimezoneEnum.AMERICA_EL_SALVADOR: return 'America/El_Salvador';
        case TimezoneEnum.AMERICA_FORT_NELSON: return 'America/Fort_Nelson';
        case TimezoneEnum.AMERICA_FORTALEZA: return 'America/Fortaleza';
        case TimezoneEnum.AMERICA_GLACE_BAY: return 'America/Glace_Bay';
        case TimezoneEnum.AMERICA_GOOSE_BAY: return 'America/Goose_Bay';
        case TimezoneEnum.AMERICA_GRAND_TURK: return 'America/Grand_Turk';
        case TimezoneEnum.AMERICA_GRENADA: return 'America/Grenada';
        case TimezoneEnum.AMERICA_GUADELOUPE: return 'America/Guadeloupe';
        case TimezoneEnum.AMERICA_GUATEMALA: return 'America/Guatemala';
        case TimezoneEnum.AMERICA_GUAYAQUIL: return 'America/Guayaquil';
        case TimezoneEnum.AMERICA_GUYANA: return 'America/Guyana';
        case TimezoneEnum.AMERICA_HALIFAX: return 'America/Halifax';
        case TimezoneEnum.AMERICA_HAVANA: return 'America/Havana';
        case TimezoneEnum.AMERICA_HERMOSILLO: return 'America/Hermosillo';
        case TimezoneEnum.AMERICA_INDIANA_INDIANAPOLIS: return 'America/Indiana/Indianapolis';
        case TimezoneEnum.AMERICA_INDIANA_KNOX: return 'America/Indiana/Knox';
        case TimezoneEnum.AMERICA_INDIANA_MARENGO: return 'America/Indiana/Marengo';
        case TimezoneEnum.AMERICA_INDIANA_PETERSBURG: return 'America/Indiana/Petersburg';
        case TimezoneEnum.AMERICA_INDIANA_TELL_CITY: return 'America/Indiana/Tell_City';
        case TimezoneEnum.AMERICA_INDIANA_VEVAY: return 'America/Indiana/Vevay';
        case TimezoneEnum.AMERICA_INDIANA_VINCENNES: return 'America/Indiana/Vincennes';
        case TimezoneEnum.AMERICA_INDIANA_WINAMAC: return 'America/Indiana/Winamac';
        case TimezoneEnum.AMERICA_INUVIK: return 'America/Inuvik';
        case TimezoneEnum.AMERICA_IQALUIT: return 'America/Iqaluit';
        case TimezoneEnum.AMERICA_JAMAICA: return 'America/Jamaica';
        case TimezoneEnum.AMERICA_JUNEAU: return 'America/Juneau';
        case TimezoneEnum.AMERICA_KENTUCKY_LOUISVILLE: return 'America/Kentucky/Louisville';
        case TimezoneEnum.AMERICA_KENTUCKY_MONTICELLO: return 'America/Kentucky/Monticello';
        case TimezoneEnum.AMERICA_KRALENDIJK: return 'America/Kralendijk';
        case TimezoneEnum.AMERICA_LA_PAZ: return 'America/La_Paz';
        case TimezoneEnum.AMERICA_LIMA: return 'America/Lima';
        case TimezoneEnum.AMERICA_LOS_ANGELES: return 'America/Los_Angeles';
        case TimezoneEnum.AMERICA_LOWER_PRINCES: return 'America/Lower_Princes';
        case TimezoneEnum.AMERICA_MACEIO: return 'America/Maceio';
        case TimezoneEnum.AMERICA_MANAGUA: return 'America/Managua';
        case TimezoneEnum.AMERICA_MANAUS: return 'America/Manaus';
        case TimezoneEnum.AMERICA_MARIGOT: return 'America/Marigot';
        case TimezoneEnum.AMERICA_MARTINIQUE: return 'America/Martinique';
        case TimezoneEnum.AMERICA_MATAMOROS: return 'America/Matamoros';
        case TimezoneEnum.AMERICA_MAZATLAN: return 'America/Mazatlan';
        case TimezoneEnum.AMERICA_MENOMINEE: return 'America/Menominee';
        case TimezoneEnum.AMERICA_MERIDA: return 'America/Merida';
        case TimezoneEnum.AMERICA_METLAKATLA: return 'America/Metlakatla';
        case TimezoneEnum.AMERICA_MEXICO_CITY: return 'America/Mexico_City';
        case TimezoneEnum.AMERICA_MIQUELON: return 'America/Miquelon';
        case TimezoneEnum.AMERICA_MONCTON: return 'America/Moncton';
        case TimezoneEnum.AMERICA_MONTERREY: return 'America/Monterrey';
        case TimezoneEnum.AMERICA_MONTEVIDEO: return 'America/Montevideo';
        case TimezoneEnum.AMERICA_MONTSERRAT: return 'America/Montserrat';
        case TimezoneEnum.AMERICA_NASSAU: return 'America/Nassau';
        case TimezoneEnum.AMERICA_NEW_YORK: return 'America/New_York';
        case TimezoneEnum.AMERICA_NOME: return 'America/Nome';
        case TimezoneEnum.AMERICA_NORONHA: return 'America/Noronha';
        case TimezoneEnum.AMERICA_NORTH_DAKOTA_BEULAH: return 'America/North_Dakota/Beulah';
        case TimezoneEnum.AMERICA_NORTH_DAKOTA_CENTER: return 'America/North_Dakota/Center';
        case TimezoneEnum.AMERICA_NORTH_DAKOTA_NEW_SALEM: return 'America/North_Dakota/New_Salem';
        case TimezoneEnum.AMERICA_NUUK: return 'America/Nuuk';
        case TimezoneEnum.AMERICA_OJINAGA: return 'America/Ojinaga';
        case TimezoneEnum.AMERICA_PANAMA: return 'America/Panama';
        case TimezoneEnum.AMERICA_PARAMARIBO: return 'America/Paramaribo';
        case TimezoneEnum.AMERICA_PHOENIX: return 'America/Phoenix';
        case TimezoneEnum.AMERICA_PORT_AU_PRINCE: return 'America/Port-au-Prince';
        case TimezoneEnum.AMERICA_PORT_OF_SPAIN: return 'America/Port_of_Spain';
        case TimezoneEnum.AMERICA_PORTO_VELHO: return 'America/Porto_Velho';
        case TimezoneEnum.AMERICA_PUERTO_RICO: return 'America/Puerto_Rico';
        case TimezoneEnum.AMERICA_PUNTA_ARENAS: return 'America/Punta_Arenas';
        case TimezoneEnum.AMERICA_RANKIN_INLET: return 'America/Rankin_Inlet';
        case TimezoneEnum.AMERICA_RECIFE: return 'America/Recife';
        case TimezoneEnum.AMERICA_REGINA: return 'America/Regina';
        case TimezoneEnum.AMERICA_RESOLUTE: return 'America/Resolute';
        case TimezoneEnum.AMERICA_RIO_BRANCO: return 'America/Rio_Branco';
        case TimezoneEnum.AMERICA_SANTAREM: return 'America/Santarem';
        case TimezoneEnum.AMERICA_SANTIAGO: return 'America/Santiago';
        case TimezoneEnum.AMERICA_SANTO_DOMINGO: return 'America/Santo_Domingo';
        case TimezoneEnum.AMERICA_SAO_PAULO: return 'America/Sao_Paulo';
        case TimezoneEnum.AMERICA_SCORESBYSUND: return 'America/Scoresbysund';
        case TimezoneEnum.AMERICA_SITKA: return 'America/Sitka';
        case TimezoneEnum.AMERICA_ST_BARTHELEMY: return 'America/St_Barthelemy';
        case TimezoneEnum.AMERICA_ST_JOHNS: return 'America/St_Johns';
        case TimezoneEnum.AMERICA_ST_KITTS: return 'America/St_Kitts';
        case TimezoneEnum.AMERICA_ST_LUCIA: return 'America/St_Lucia';
        case TimezoneEnum.AMERICA_ST_THOMAS: return 'America/St_Thomas';
        case TimezoneEnum.AMERICA_ST_VINCENT: return 'America/St_Vincent';
        case TimezoneEnum.AMERICA_SWIFT_CURRENT: return 'America/Swift_Current';
        case TimezoneEnum.AMERICA_TEGUCIGALPA: return 'America/Tegucigalpa';
        case TimezoneEnum.AMERICA_THULE: return 'America/Thule';
        case TimezoneEnum.AMERICA_TIJUANA: return 'America/Tijuana';
        case TimezoneEnum.AMERICA_TORONTO: return 'America/Toronto';
        case TimezoneEnum.AMERICA_TORTOLA: return 'America/Tortola';
        case TimezoneEnum.AMERICA_VANCOUVER: return 'America/Vancouver';
        case TimezoneEnum.AMERICA_WHITEHORSE: return 'America/Whitehorse';
        case TimezoneEnum.AMERICA_WINNIPEG: return 'America/Winnipeg';
        case TimezoneEnum.AMERICA_YAKUTAT: return 'America/Yakutat';
        case TimezoneEnum.ANTARCTICA_CASEY: return 'Antarctica/Casey';
        case TimezoneEnum.ANTARCTICA_DAVIS: return 'Antarctica/Davis';
        case TimezoneEnum.ANTARCTICA_DUMONTDURVILLE: return 'Antarctica/DumontDUrville';
        case TimezoneEnum.ANTARCTICA_MACQUARIE: return 'Antarctica/Macquarie';
        case TimezoneEnum.ANTARCTICA_MAWSON: return 'Antarctica/Mawson';
        case TimezoneEnum.ANTARCTICA_MCMURDO: return 'Antarctica/McMurdo';
        case TimezoneEnum.ANTARCTICA_PALMER: return 'Antarctica/Palmer';
        case TimezoneEnum.ANTARCTICA_ROTHERA: return 'Antarctica/Rothera';
        case TimezoneEnum.ANTARCTICA_SYOWA: return 'Antarctica/Syowa';
        case TimezoneEnum.ANTARCTICA_TROLL: return 'Antarctica/Troll';
        case TimezoneEnum.ANTARCTICA_VOSTOK: return 'Antarctica/Vostok';
        case TimezoneEnum.ARCTIC_LONGYEARBYEN: return 'Arctic/Longyearbyen';
        case TimezoneEnum.ASIA_ADEN: return 'Asia/Aden';
        case TimezoneEnum.ASIA_ALMATY: return 'Asia/Almaty';
        case TimezoneEnum.ASIA_AMMAN: return 'Asia/Amman';
        case TimezoneEnum.ASIA_ANADYR: return 'Asia/Anadyr';
        case TimezoneEnum.ASIA_AQTAU: return 'Asia/Aqtau';
        case TimezoneEnum.ASIA_AQTOBE: return 'Asia/Aqtobe';
        case TimezoneEnum.ASIA_ASHGABAT: return 'Asia/Ashgabat';
        case TimezoneEnum.ASIA_ATYRAU: return 'Asia/Atyrau';
        case TimezoneEnum.ASIA_BAGHDAD: return 'Asia/Baghdad';
        case TimezoneEnum.ASIA_BAHRAIN: return 'Asia/Bahrain';
        case TimezoneEnum.ASIA_BAKU: return 'Asia/Baku';
        case TimezoneEnum.ASIA_BANGKOK: return 'Asia/Bangkok';
        case TimezoneEnum.ASIA_BARNAUL: return 'Asia/Barnaul';
        case TimezoneEnum.ASIA_BEIRUT: return 'Asia/Beirut';
        case TimezoneEnum.ASIA_BISHKEK: return 'Asia/Bishkek';
        case TimezoneEnum.ASIA_BRUNEI: return 'Asia/Brunei';
        case TimezoneEnum.ASIA_CHITA: return 'Asia/Chita';
        case TimezoneEnum.ASIA_CHOIBALSAN: return 'Asia/Choibalsan';
        case TimezoneEnum.ASIA_COLOMBO: return 'Asia/Colombo';
        case TimezoneEnum.ASIA_DAMASCUS: return 'Asia/Damascus';
        case TimezoneEnum.ASIA_DHAKA: return 'Asia/Dhaka';
        case TimezoneEnum.ASIA_DILI: return 'Asia/Dili';
        case TimezoneEnum.ASIA_DUBAI: return 'Asia/Dubai';
        case TimezoneEnum.ASIA_DUSHANBE: return 'Asia/Dushanbe';
        case TimezoneEnum.ASIA_FAMAGUSTA: return 'Asia/Famagusta';
        case TimezoneEnum.ASIA_GAZA: return 'Asia/Gaza';
        case TimezoneEnum.ASIA_HEBRON: return 'Asia/Hebron';
        case TimezoneEnum.ASIA_HO_CHI_MINH: return 'Asia/Ho_Chi_Minh';
        case TimezoneEnum.ASIA_HONG_KONG: return 'Asia/Hong_Kong';
        case TimezoneEnum.ASIA_HOVD: return 'Asia/Hovd';
        case TimezoneEnum.ASIA_IRKUTSK: return 'Asia/Irkutsk';
        case TimezoneEnum.ASIA_JAKARTA: return 'Asia/Jakarta';
        case TimezoneEnum.ASIA_JAYAPURA: return 'Asia/Jayapura';
        case TimezoneEnum.ASIA_JERUSALEM: return 'Asia/Jerusalem';
        case TimezoneEnum.ASIA_KABUL: return 'Asia/Kabul';
        case TimezoneEnum.ASIA_KAMCHATKA: return 'Asia/Kamchatka';
        case TimezoneEnum.ASIA_KARACHI: return 'Asia/Karachi';
        case TimezoneEnum.ASIA_KATHMANDU: return 'Asia/Kathmandu';
        case TimezoneEnum.ASIA_KHANDYGA: return 'Asia/Khandyga';
        case TimezoneEnum.ASIA_KOLKATA: return 'Asia/Kolkata';
        case TimezoneEnum.ASIA_KRASNOYARSK: return 'Asia/Krasnoyarsk';
        case TimezoneEnum.ASIA_KUALA_LUMPUR: return 'Asia/Kuala_Lumpur';
        case TimezoneEnum.ASIA_KUCHING: return 'Asia/Kuching';
        case TimezoneEnum.ASIA_KUWAIT: return 'Asia/Kuwait';
        case TimezoneEnum.ASIA_MACAU: return 'Asia/Macau';
        case TimezoneEnum.ASIA_MAGADAN: return 'Asia/Magadan';
        case TimezoneEnum.ASIA_MAKASSAR: return 'Asia/Makassar';
        case TimezoneEnum.ASIA_MANILA: return 'Asia/Manila';
        case TimezoneEnum.ASIA_MUSCAT: return 'Asia/Muscat';
        case TimezoneEnum.ASIA_NICOSIA: return 'Asia/Nicosia';
        case TimezoneEnum.ASIA_NOVOKUZNETSK: return 'Asia/Novokuznetsk';
        case TimezoneEnum.ASIA_NOVOSIBIRSK: return 'Asia/Novosibirsk';
        case TimezoneEnum.ASIA_OMSK: return 'Asia/Omsk';
        case TimezoneEnum.ASIA_ORAL: return 'Asia/Oral';
        case TimezoneEnum.ASIA_PHNOM_PENH: return 'Asia/Phnom_Penh';
        case TimezoneEnum.ASIA_PONTIANAK: return 'Asia/Pontianak';
        case TimezoneEnum.ASIA_PYONGYANG: return 'Asia/Pyongyang';
        case TimezoneEnum.ASIA_QATAR: return 'Asia/Qatar';
        case TimezoneEnum.ASIA_QOSTANAY: return 'Asia/Qostanay';
        case TimezoneEnum.ASIA_QYZYLORDA: return 'Asia/Qyzylorda';
        case TimezoneEnum.ASIA_RIYADH: return 'Asia/Riyadh';
        case TimezoneEnum.ASIA_SAKHALIN: return 'Asia/Sakhalin';
        case TimezoneEnum.ASIA_SAMARKAND: return 'Asia/Samarkand';
        case TimezoneEnum.ASIA_SEOUL: return 'Asia/Seoul';
        case TimezoneEnum.ASIA_SHANGHAI: return 'Asia/Shanghai';
        case TimezoneEnum.ASIA_SINGAPORE: return 'Asia/Singapore';
        case TimezoneEnum.ASIA_SREDNEKOLYMSK: return 'Asia/Srednekolymsk';
        case TimezoneEnum.ASIA_TAIPEI: return 'Asia/Taipei';
        case TimezoneEnum.ASIA_TASHKENT: return 'Asia/Tashkent';
        case TimezoneEnum.ASIA_TBILISI: return 'Asia/Tbilisi';
        case TimezoneEnum.ASIA_TEHRAN: return 'Asia/Tehran';
        case TimezoneEnum.ASIA_THIMPHU: return 'Asia/Thimphu';
        case TimezoneEnum.ASIA_TOKYO: return 'Asia/Tokyo';
        case TimezoneEnum.ASIA_TOMSK: return 'Asia/Tomsk';
        case TimezoneEnum.ASIA_ULAANBAATAR: return 'Asia/Ulaanbaatar';
        case TimezoneEnum.ASIA_URUMQI: return 'Asia/Urumqi';
        case TimezoneEnum.ASIA_UST_NERA: return 'Asia/Ust-Nera';
        case TimezoneEnum.ASIA_VIENTIANE: return 'Asia/Vientiane';
        case TimezoneEnum.ASIA_VLADIVOSTOK: return 'Asia/Vladivostok';
        case TimezoneEnum.ASIA_YAKUTSK: return 'Asia/Yakutsk';
        case TimezoneEnum.ASIA_YANGON: return 'Asia/Yangon';
        case TimezoneEnum.ASIA_YEKATERINBURG: return 'Asia/Yekaterinburg';
        case TimezoneEnum.ASIA_YEREVAN: return 'Asia/Yerevan';
        case TimezoneEnum.ATLANTIC_AZORES: return 'Atlantic/Azores';
        case TimezoneEnum.ATLANTIC_BERMUDA: return 'Atlantic/Bermuda';
        case TimezoneEnum.ATLANTIC_CANARY: return 'Atlantic/Canary';
        case TimezoneEnum.ATLANTIC_CAPE_VERDE: return 'Atlantic/Cape_Verde';
        case TimezoneEnum.ATLANTIC_FAROE: return 'Atlantic/Faroe';
        case TimezoneEnum.ATLANTIC_MADEIRA: return 'Atlantic/Madeira';
        case TimezoneEnum.ATLANTIC_REYKJAVIK: return 'Atlantic/Reykjavik';
        case TimezoneEnum.ATLANTIC_SOUTH_GEORGIA: return 'Atlantic/South_Georgia';
        case TimezoneEnum.ATLANTIC_ST_HELENA: return 'Atlantic/St_Helena';
        case TimezoneEnum.ATLANTIC_STANLEY: return 'Atlantic/Stanley';
        case TimezoneEnum.AUSTRALIA_ADELAIDE: return 'Australia/Adelaide';
        case TimezoneEnum.AUSTRALIA_BRISBANE: return 'Australia/Brisbane';
        case TimezoneEnum.AUSTRALIA_BROKEN_HILL: return 'Australia/Broken_Hill';
        case TimezoneEnum.AUSTRALIA_DARWIN: return 'Australia/Darwin';
        case TimezoneEnum.AUSTRALIA_EUCLA: return 'Australia/Eucla';
        case TimezoneEnum.AUSTRALIA_HOBART: return 'Australia/Hobart';
        case TimezoneEnum.AUSTRALIA_LINDEMAN: return 'Australia/Lindeman';
        case TimezoneEnum.AUSTRALIA_LORD_HOWE: return 'Australia/Lord_Howe';
        case TimezoneEnum.AUSTRALIA_MELBOURNE: return 'Australia/Melbourne';
        case TimezoneEnum.AUSTRALIA_PERTH: return 'Australia/Perth';
        case TimezoneEnum.AUSTRALIA_SYDNEY: return 'Australia/Sydney';
        case TimezoneEnum.EUROPE_AMSTERDAM: return 'Europe/Amsterdam';
        case TimezoneEnum.EUROPE_ANDORRA: return 'Europe/Andorra';
        case TimezoneEnum.EUROPE_ASTRAKHAN: return 'Europe/Astrakhan';
        case TimezoneEnum.EUROPE_ATHENS: return 'Europe/Athens';
        case TimezoneEnum.EUROPE_BELGRADE: return 'Europe/Belgrade';
        case TimezoneEnum.EUROPE_BERLIN: return 'Europe/Berlin';
        case TimezoneEnum.EUROPE_BRATISLAVA: return 'Europe/Bratislava';
        case TimezoneEnum.EUROPE_BRUSSELS: return 'Europe/Brussels';
        case TimezoneEnum.EUROPE_BUCHAREST: return 'Europe/Bucharest';
        case TimezoneEnum.EUROPE_BUDAPEST: return 'Europe/Budapest';
        case TimezoneEnum.EUROPE_BUSINGEN: return 'Europe/Busingen';
        case TimezoneEnum.EUROPE_CHISINAU: return 'Europe/Chisinau';
        case TimezoneEnum.EUROPE_COPENHAGEN: return 'Europe/Copenhagen';
        case TimezoneEnum.EUROPE_DUBLIN: return 'Europe/Dublin';
        case TimezoneEnum.EUROPE_GIBRALTAR: return 'Europe/Gibraltar';
        case TimezoneEnum.EUROPE_GUERNSEY: return 'Europe/Guernsey';
        case TimezoneEnum.EUROPE_HELSINKI: return 'Europe/Helsinki';
        case TimezoneEnum.EUROPE_ISLE_OF_MAN: return 'Europe/Isle_of_Man';
        case TimezoneEnum.EUROPE_ISTANBUL: return 'Europe/Istanbul';
        case TimezoneEnum.EUROPE_JERSEY: return 'Europe/Jersey';
        case TimezoneEnum.EUROPE_KALININGRAD: return 'Europe/Kaliningrad';
        case TimezoneEnum.EUROPE_KIROV: return 'Europe/Kirov';
        case TimezoneEnum.EUROPE_KYIV: return 'Europe/Kyiv';
        case TimezoneEnum.EUROPE_LISBON: return 'Europe/Lisbon';
        case TimezoneEnum.EUROPE_LJUBLJANA: return 'Europe/Ljubljana';
        case TimezoneEnum.EUROPE_LONDON: return 'Europe/London';
        case TimezoneEnum.EUROPE_LUXEMBOURG: return 'Europe/Luxembourg';
        case TimezoneEnum.EUROPE_MADRID: return 'Europe/Madrid';
        case TimezoneEnum.EUROPE_MALTA: return 'Europe/Malta';
        case TimezoneEnum.EUROPE_MARIEHAMN: return 'Europe/Mariehamn';
        case TimezoneEnum.EUROPE_MINSK: return 'Europe/Minsk';
        case TimezoneEnum.EUROPE_MONACO: return 'Europe/Monaco';
        case TimezoneEnum.EUROPE_MOSCOW: return 'Europe/Moscow';
        case TimezoneEnum.EUROPE_OSLO: return 'Europe/Oslo';
        case TimezoneEnum.EUROPE_PARIS: return 'Europe/Paris';
        case TimezoneEnum.EUROPE_PODGORICA: return 'Europe/Podgorica';
        case TimezoneEnum.EUROPE_PRAGUE: return 'Europe/Prague';
        case TimezoneEnum.EUROPE_RIGA: return 'Europe/Riga';
        case TimezoneEnum.EUROPE_ROME: return 'Europe/Rome';
        case TimezoneEnum.EUROPE_SAMARA: return 'Europe/Samara';
        case TimezoneEnum.EUROPE_SAN_MARINO: return 'Europe/San_Marino';
        case TimezoneEnum.EUROPE_SARAJEVO: return 'Europe/Sarajevo';
        case TimezoneEnum.EUROPE_SARATOV: return 'Europe/Saratov';
        case TimezoneEnum.EUROPE_SIMFEROPOL: return 'Europe/Simferopol';
        case TimezoneEnum.EUROPE_SKOPJE: return 'Europe/Skopje';
        case TimezoneEnum.EUROPE_SOFIA: return 'Europe/Sofia';
        case TimezoneEnum.EUROPE_STOCKHOLM: return 'Europe/Stockholm';
        case TimezoneEnum.EUROPE_TALLINN: return 'Europe/Tallinn';
        case TimezoneEnum.EUROPE_TIRANE: return 'Europe/Tirane';
        case TimezoneEnum.EUROPE_ULYANOVSK: return 'Europe/Ulyanovsk';
        case TimezoneEnum.EUROPE_VADUZ: return 'Europe/Vaduz';
        case TimezoneEnum.EUROPE_VATICAN: return 'Europe/Vatican';
        case TimezoneEnum.EUROPE_VIENNA: return 'Europe/Vienna';
        case TimezoneEnum.EUROPE_VILNIUS: return 'Europe/Vilnius';
        case TimezoneEnum.EUROPE_VOLGOGRAD: return 'Europe/Volgograd';
        case TimezoneEnum.EUROPE_WARSAW: return 'Europe/Warsaw';
        case TimezoneEnum.EUROPE_ZAGREB: return 'Europe/Zagreb';
        case TimezoneEnum.EUROPE_ZURICH: return 'Europe/Zurich';
        case TimezoneEnum.INDIAN_ANTANANARIVO: return 'Indian/Antananarivo';
        case TimezoneEnum.INDIAN_CHAGOS: return 'Indian/Chagos';
        case TimezoneEnum.INDIAN_CHRISTMAS: return 'Indian/Christmas';
        case TimezoneEnum.INDIAN_COCOS: return 'Indian/Cocos';
        case TimezoneEnum.INDIAN_COMORO: return 'Indian/Comoro';
        case TimezoneEnum.INDIAN_KERGUELEN: return 'Indian/Kerguelen';
        case TimezoneEnum.INDIAN_MAHE: return 'Indian/Mahe';
        case TimezoneEnum.INDIAN_MALDIVES: return 'Indian/Maldives';
        case TimezoneEnum.INDIAN_MAURITIUS: return 'Indian/Mauritius';
        case TimezoneEnum.INDIAN_MAYOTTE: return 'Indian/Mayotte';
        case TimezoneEnum.INDIAN_REUNION: return 'Indian/Reunion';
        case TimezoneEnum.PACIFIC_APIA: return 'Pacific/Apia';
        case TimezoneEnum.PACIFIC_AUCKLAND: return 'Pacific/Auckland';
        case TimezoneEnum.PACIFIC_BOUGAINVILLE: return 'Pacific/Bougainville';
        case TimezoneEnum.PACIFIC_CHATHAM: return 'Pacific/Chatham';
        case TimezoneEnum.PACIFIC_CHUUK: return 'Pacific/Chuuk';
        case TimezoneEnum.PACIFIC_EASTER: return 'Pacific/Easter';
        case TimezoneEnum.PACIFIC_EFATE: return 'Pacific/Efate';
        case TimezoneEnum.PACIFIC_FAKAOFO: return 'Pacific/Fakaofo';
        case TimezoneEnum.PACIFIC_FIJI: return 'Pacific/Fiji';
        case TimezoneEnum.PACIFIC_FUNAFUTI: return 'Pacific/Funafuti';
        case TimezoneEnum.PACIFIC_GALAPAGOS: return 'Pacific/Galapagos';
        case TimezoneEnum.PACIFIC_GAMBIER: return 'Pacific/Gambier';
        case TimezoneEnum.PACIFIC_GUADALCANAL: return 'Pacific/Guadalcanal';
        case TimezoneEnum.PACIFIC_GUAM: return 'Pacific/Guam';
        case TimezoneEnum.PACIFIC_HONOLULU: return 'Pacific/Honolulu';
        case TimezoneEnum.PACIFIC_KANTON: return 'Pacific/Kanton';
        case TimezoneEnum.PACIFIC_KIRITIMATI: return 'Pacific/Kiritimati';
        case TimezoneEnum.PACIFIC_KOSRAE: return 'Pacific/Kosrae';
        case TimezoneEnum.PACIFIC_KWAJALEIN: return 'Pacific/Kwajalein';
        case TimezoneEnum.PACIFIC_MAJURO: return 'Pacific/Majuro';
        case TimezoneEnum.PACIFIC_MARQUESAS: return 'Pacific/Marquesas';
        case TimezoneEnum.PACIFIC_MIDWAY: return 'Pacific/Midway';
        case TimezoneEnum.PACIFIC_NAURU: return 'Pacific/Nauru';
        case TimezoneEnum.PACIFIC_NIUE: return 'Pacific/Niue';
        case TimezoneEnum.PACIFIC_NORFOLK: return 'Pacific/Norfolk';
        case TimezoneEnum.PACIFIC_NOUMEA: return 'Pacific/Noumea';
        case TimezoneEnum.PACIFIC_PAGO_PAGO: return 'Pacific/Pago_Pago';
        case TimezoneEnum.PACIFIC_PALAU: return 'Pacific/Palau';
        case TimezoneEnum.PACIFIC_PITCAIRN: return 'Pacific/Pitcairn';
        case TimezoneEnum.PACIFIC_POHNPEI: return 'Pacific/Pohnpei';
        case TimezoneEnum.PACIFIC_PORT_MORESBY: return 'Pacific/Port_Moresby';
        case TimezoneEnum.PACIFIC_RAROTONGA: return 'Pacific/Rarotonga';
        case TimezoneEnum.PACIFIC_SAIPAN: return 'Pacific/Saipan';
        case TimezoneEnum.PACIFIC_TAHITI: return 'Pacific/Tahiti';
        case TimezoneEnum.PACIFIC_TARAWA: return 'Pacific/Tarawa';
        case TimezoneEnum.PACIFIC_TONGATAPU: return 'Pacific/Tongatapu';
        case TimezoneEnum.PACIFIC_WAKE: return 'Pacific/Wake';
        case TimezoneEnum.PACIFIC_WALLIS: return 'Pacific/Wallis';
        case TimezoneEnum.UTC:
        default:
            return 'UTC';
    }
};
export const convertFromIdentifierToEnumValue = (id) => {
    switch (id) {
        case 'Africa/Abidjan': return TimezoneEnum.AFRICA_ABIDJAN;
        case 'Africa/Accra': return TimezoneEnum.AFRICA_ACCRA;
        case 'Africa/Addis_Ababa': return TimezoneEnum.AFRICA_ADDIS_ABABA;
        case 'Africa/Algiers': return TimezoneEnum.AFRICA_ALGIERS;
        case 'Africa/Asmara': return TimezoneEnum.AFRICA_ASMARA;
        case 'Africa/Bamako': return TimezoneEnum.AFRICA_BAMAKO;
        case 'Africa/Bangui': return TimezoneEnum.AFRICA_BANGUI;
        case 'Africa/Banjul': return TimezoneEnum.AFRICA_BANJUL;
        case 'Africa/Bissau': return TimezoneEnum.AFRICA_BISSAU;
        case 'Africa/Blantyre': return TimezoneEnum.AFRICA_BLANTYRE;
        case 'Africa/Brazzaville': return TimezoneEnum.AFRICA_BRAZZAVILLE;
        case 'Africa/Bujumbura': return TimezoneEnum.AFRICA_BUJUMBURA;
        case 'Africa/Cairo': return TimezoneEnum.AFRICA_CAIRO;
        case 'Africa/Casablanca': return TimezoneEnum.AFRICA_CASABLANCA;
        case 'Africa/Ceuta': return TimezoneEnum.AFRICA_CEUTA;
        case 'Africa/Conakry': return TimezoneEnum.AFRICA_CONAKRY;
        case 'Africa/Dakar': return TimezoneEnum.AFRICA_DAKAR;
        case 'Africa/Dar_es_Salaam': return TimezoneEnum.AFRICA_DAR_ES_SALAAM;
        case 'Africa/Djibouti': return TimezoneEnum.AFRICA_DJIBOUTI;
        case 'Africa/Douala': return TimezoneEnum.AFRICA_DOUALA;
        case 'Africa/El_Aaiun': return TimezoneEnum.AFRICA_EL_AAIUN;
        case 'Africa/Freetown': return TimezoneEnum.AFRICA_FREETOWN;
        case 'Africa/Gaborone': return TimezoneEnum.AFRICA_GABORONE;
        case 'Africa/Harare': return TimezoneEnum.AFRICA_HARARE;
        case 'Africa/Johannesburg': return TimezoneEnum.AFRICA_JOHANNESBURG;
        case 'Africa/Juba': return TimezoneEnum.AFRICA_JUBA;
        case 'Africa/Kampala': return TimezoneEnum.AFRICA_KAMPALA;
        case 'Africa/Khartoum': return TimezoneEnum.AFRICA_KHARTOUM;
        case 'Africa/Kigali': return TimezoneEnum.AFRICA_KIGALI;
        case 'Africa/Kinshasa': return TimezoneEnum.AFRICA_KINSHASA;
        case 'Africa/Lagos': return TimezoneEnum.AFRICA_LAGOS;
        case 'Africa/Libreville': return TimezoneEnum.AFRICA_LIBREVILLE;
        case 'Africa/Lome': return TimezoneEnum.AFRICA_LOME;
        case 'Africa/Luanda': return TimezoneEnum.AFRICA_LUANDA;
        case 'Africa/Lubumbashi': return TimezoneEnum.AFRICA_LUBUMBASHI;
        case 'Africa/Lusaka': return TimezoneEnum.AFRICA_LUSAKA;
        case 'Africa/Malabo': return TimezoneEnum.AFRICA_MALABO;
        case 'Africa/Maputo': return TimezoneEnum.AFRICA_MAPUTO;
        case 'Africa/Maseru': return TimezoneEnum.AFRICA_MASERU;
        case 'Africa/Mbabane': return TimezoneEnum.AFRICA_MBABANE;
        case 'Africa/Mogadishu': return TimezoneEnum.AFRICA_MOGADISHU;
        case 'Africa/Monrovia': return TimezoneEnum.AFRICA_MONROVIA;
        case 'Africa/Nairobi': return TimezoneEnum.AFRICA_NAIROBI;
        case 'Africa/Ndjamena': return TimezoneEnum.AFRICA_NDJAMENA;
        case 'Africa/Niamey': return TimezoneEnum.AFRICA_NIAMEY;
        case 'Africa/Nouakchott': return TimezoneEnum.AFRICA_NOUAKCHOTT;
        case 'Africa/Ouagadougou': return TimezoneEnum.AFRICA_OUAGADOUGOU;
        case 'Africa/Porto-Novo': return TimezoneEnum.AFRICA_PORTO_NOVO;
        case 'Africa/Sao_Tome': return TimezoneEnum.AFRICA_SAO_TOME;
        case 'Africa/Tripoli': return TimezoneEnum.AFRICA_TRIPOLI;
        case 'Africa/Tunis': return TimezoneEnum.AFRICA_TUNIS;
        case 'Africa/Windhoek': return TimezoneEnum.AFRICA_WINDHOEK;
        case 'America/Adak': return TimezoneEnum.AMERICA_ADAK;
        case 'America/Anchorage': return TimezoneEnum.AMERICA_ANCHORAGE;
        case 'America/Anguilla': return TimezoneEnum.AMERICA_ANGUILLA;
        case 'America/Antigua': return TimezoneEnum.AMERICA_ANTIGUA;
        case 'America/Araguaina': return TimezoneEnum.AMERICA_ARAGUAINA;
        case 'America/Argentina/Buenos_Aires': return TimezoneEnum.AMERICA_ARGENTINA_BUENOS_AIRES;
        case 'America/Argentina/Catamarca': return TimezoneEnum.AMERICA_ARGENTINA_CATAMARCA;
        case 'America/Argentina/Cordoba': return TimezoneEnum.AMERICA_ARGENTINA_CORDOBA;
        case 'America/Argentina/Jujuy': return TimezoneEnum.AMERICA_ARGENTINA_JUJUY;
        case 'America/Argentina/La_Rioja': return TimezoneEnum.AMERICA_ARGENTINA_LA_RIOJA;
        case 'America/Argentina/Mendoza': return TimezoneEnum.AMERICA_ARGENTINA_MENDOZA;
        case 'America/Argentina/Rio_Gallegos': return TimezoneEnum.AMERICA_ARGENTINA_RIO_GALLEGOS;
        case 'America/Argentina/Salta': return TimezoneEnum.AMERICA_ARGENTINA_SALTA;
        case 'America/Argentina/San_Juan': return TimezoneEnum.AMERICA_ARGENTINA_SAN_JUAN;
        case 'America/Argentina/San_Luis': return TimezoneEnum.AMERICA_ARGENTINA_SAN_LUIS;
        case 'America/Argentina/Tucuman': return TimezoneEnum.AMERICA_ARGENTINA_TUCUMAN;
        case 'America/Argentina/Ushuaia': return TimezoneEnum.AMERICA_ARGENTINA_USHUAIA;
        case 'America/Aruba': return TimezoneEnum.AMERICA_ARUBA;
        case 'America/Asuncion': return TimezoneEnum.AMERICA_ASUNCION;
        case 'America/Atikokan': return TimezoneEnum.AMERICA_ATIKOKAN;
        case 'America/Bahia': return TimezoneEnum.AMERICA_BAHIA;
        case 'America/Bahia_Banderas': return TimezoneEnum.AMERICA_BAHIA_BANDERAS;
        case 'America/Barbados': return TimezoneEnum.AMERICA_BARBADOS;
        case 'America/Belem': return TimezoneEnum.AMERICA_BELEM;
        case 'America/Belize': return TimezoneEnum.AMERICA_BELIZE;
        case 'America/Blanc-Sablon': return TimezoneEnum.AMERICA_BLANC_SABLON;
        case 'America/Boa_Vista': return TimezoneEnum.AMERICA_BOA_VISTA;
        case 'America/Bogota': return TimezoneEnum.AMERICA_BOGOTA;
        case 'America/Boise': return TimezoneEnum.AMERICA_BOISE;
        case 'America/Cambridge_Bay': return TimezoneEnum.AMERICA_CAMBRIDGE_BAY;
        case 'America/Campo_Grande': return TimezoneEnum.AMERICA_CAMPO_GRANDE;
        case 'America/Cancun': return TimezoneEnum.AMERICA_CANCUN;
        case 'America/Caracas': return TimezoneEnum.AMERICA_CARACAS;
        case 'America/Cayenne': return TimezoneEnum.AMERICA_CAYENNE;
        case 'America/Cayman': return TimezoneEnum.AMERICA_CAYMAN;
        case 'America/Chicago': return TimezoneEnum.AMERICA_CHICAGO;
        case 'America/Chihuahua': return TimezoneEnum.AMERICA_CHIHUAHUA;
        case 'America/Ciudad_Juarez': return TimezoneEnum.AMERICA_CIUDAD_JUAREZ;
        case 'America/Costa_Rica': return TimezoneEnum.AMERICA_COSTA_RICA;
        case 'America/Creston': return TimezoneEnum.AMERICA_CRESTON;
        case 'America/Cuiaba': return TimezoneEnum.AMERICA_CUIABA;
        case 'America/Curacao': return TimezoneEnum.AMERICA_CURACAO;
        case 'America/Danmarkshavn': return TimezoneEnum.AMERICA_DANMARKSHAVN;
        case 'America/Dawson': return TimezoneEnum.AMERICA_DAWSON;
        case 'America/Dawson_Creek': return TimezoneEnum.AMERICA_DAWSON_CREEK;
        case 'America/Denver': return TimezoneEnum.AMERICA_DENVER;
        case 'America/Detroit': return TimezoneEnum.AMERICA_DETROIT;
        case 'America/Dominica': return TimezoneEnum.AMERICA_DOMINICA;
        case 'America/Edmonton': return TimezoneEnum.AMERICA_EDMONTON;
        case 'America/Eirunepe': return TimezoneEnum.AMERICA_EIRUNEPE;
        case 'America/El_Salvador': return TimezoneEnum.AMERICA_EL_SALVADOR;
        case 'America/Fort_Nelson': return TimezoneEnum.AMERICA_FORT_NELSON;
        case 'America/Fortaleza': return TimezoneEnum.AMERICA_FORTALEZA;
        case 'America/Glace_Bay': return TimezoneEnum.AMERICA_GLACE_BAY;
        case 'America/Goose_Bay': return TimezoneEnum.AMERICA_GOOSE_BAY;
        case 'America/Grand_Turk': return TimezoneEnum.AMERICA_GRAND_TURK;
        case 'America/Grenada': return TimezoneEnum.AMERICA_GRENADA;
        case 'America/Guadeloupe': return TimezoneEnum.AMERICA_GUADELOUPE;
        case 'America/Guatemala': return TimezoneEnum.AMERICA_GUATEMALA;
        case 'America/Guayaquil': return TimezoneEnum.AMERICA_GUAYAQUIL;
        case 'America/Guyana': return TimezoneEnum.AMERICA_GUYANA;
        case 'America/Halifax': return TimezoneEnum.AMERICA_HALIFAX;
        case 'America/Havana': return TimezoneEnum.AMERICA_HAVANA;
        case 'America/Hermosillo': return TimezoneEnum.AMERICA_HERMOSILLO;
        case 'America/Indiana/Indianapolis': return TimezoneEnum.AMERICA_INDIANA_INDIANAPOLIS;
        case 'America/Indiana/Knox': return TimezoneEnum.AMERICA_INDIANA_KNOX;
        case 'America/Indiana/Marengo': return TimezoneEnum.AMERICA_INDIANA_MARENGO;
        case 'America/Indiana/Petersburg': return TimezoneEnum.AMERICA_INDIANA_PETERSBURG;
        case 'America/Indiana/Tell_City': return TimezoneEnum.AMERICA_INDIANA_TELL_CITY;
        case 'America/Indiana/Vevay': return TimezoneEnum.AMERICA_INDIANA_VEVAY;
        case 'America/Indiana/Vincennes': return TimezoneEnum.AMERICA_INDIANA_VINCENNES;
        case 'America/Indiana/Winamac': return TimezoneEnum.AMERICA_INDIANA_WINAMAC;
        case 'America/Inuvik': return TimezoneEnum.AMERICA_INUVIK;
        case 'America/Iqaluit': return TimezoneEnum.AMERICA_IQALUIT;
        case 'America/Jamaica': return TimezoneEnum.AMERICA_JAMAICA;
        case 'America/Juneau': return TimezoneEnum.AMERICA_JUNEAU;
        case 'America/Kentucky/Louisville': return TimezoneEnum.AMERICA_KENTUCKY_LOUISVILLE;
        case 'America/Kentucky/Monticello': return TimezoneEnum.AMERICA_KENTUCKY_MONTICELLO;
        case 'America/Kralendijk': return TimezoneEnum.AMERICA_KRALENDIJK;
        case 'America/La_Paz': return TimezoneEnum.AMERICA_LA_PAZ;
        case 'America/Lima': return TimezoneEnum.AMERICA_LIMA;
        case 'America/Los_Angeles': return TimezoneEnum.AMERICA_LOS_ANGELES;
        case 'America/Lower_Princes': return TimezoneEnum.AMERICA_LOWER_PRINCES;
        case 'America/Maceio': return TimezoneEnum.AMERICA_MACEIO;
        case 'America/Managua': return TimezoneEnum.AMERICA_MANAGUA;
        case 'America/Manaus': return TimezoneEnum.AMERICA_MANAUS;
        case 'America/Marigot': return TimezoneEnum.AMERICA_MARIGOT;
        case 'America/Martinique': return TimezoneEnum.AMERICA_MARTINIQUE;
        case 'America/Matamoros': return TimezoneEnum.AMERICA_MATAMOROS;
        case 'America/Mazatlan': return TimezoneEnum.AMERICA_MAZATLAN;
        case 'America/Menominee': return TimezoneEnum.AMERICA_MENOMINEE;
        case 'America/Merida': return TimezoneEnum.AMERICA_MERIDA;
        case 'America/Metlakatla': return TimezoneEnum.AMERICA_METLAKATLA;
        case 'America/Mexico_City': return TimezoneEnum.AMERICA_MEXICO_CITY;
        case 'America/Miquelon': return TimezoneEnum.AMERICA_MIQUELON;
        case 'America/Moncton': return TimezoneEnum.AMERICA_MONCTON;
        case 'America/Monterrey': return TimezoneEnum.AMERICA_MONTERREY;
        case 'America/Montevideo': return TimezoneEnum.AMERICA_MONTEVIDEO;
        case 'America/Montserrat': return TimezoneEnum.AMERICA_MONTSERRAT;
        case 'America/Nassau': return TimezoneEnum.AMERICA_NASSAU;
        case 'America/New_York': return TimezoneEnum.AMERICA_NEW_YORK;
        case 'America/Nome': return TimezoneEnum.AMERICA_NOME;
        case 'America/Noronha': return TimezoneEnum.AMERICA_NORONHA;
        case 'America/North_Dakota/Beulah': return TimezoneEnum.AMERICA_NORTH_DAKOTA_BEULAH;
        case 'America/North_Dakota/Center': return TimezoneEnum.AMERICA_NORTH_DAKOTA_CENTER;
        case 'America/North_Dakota/New_Salem': return TimezoneEnum.AMERICA_NORTH_DAKOTA_NEW_SALEM;
        case 'America/Nuuk': return TimezoneEnum.AMERICA_NUUK;
        case 'America/Ojinaga': return TimezoneEnum.AMERICA_OJINAGA;
        case 'America/Panama': return TimezoneEnum.AMERICA_PANAMA;
        case 'America/Paramaribo': return TimezoneEnum.AMERICA_PARAMARIBO;
        case 'America/Phoenix': return TimezoneEnum.AMERICA_PHOENIX;
        case 'America/Port-au-Prince': return TimezoneEnum.AMERICA_PORT_AU_PRINCE;
        case 'America/Port_of_Spain': return TimezoneEnum.AMERICA_PORT_OF_SPAIN;
        case 'America/Porto_Velho': return TimezoneEnum.AMERICA_PORTO_VELHO;
        case 'America/Puerto_Rico': return TimezoneEnum.AMERICA_PUERTO_RICO;
        case 'America/Punta_Arenas': return TimezoneEnum.AMERICA_PUNTA_ARENAS;
        case 'America/Rankin_Inlet': return TimezoneEnum.AMERICA_RANKIN_INLET;
        case 'America/Recife': return TimezoneEnum.AMERICA_RECIFE;
        case 'America/Regina': return TimezoneEnum.AMERICA_REGINA;
        case 'America/Resolute': return TimezoneEnum.AMERICA_RESOLUTE;
        case 'America/Rio_Branco': return TimezoneEnum.AMERICA_RIO_BRANCO;
        case 'America/Santarem': return TimezoneEnum.AMERICA_SANTAREM;
        case 'America/Santiago': return TimezoneEnum.AMERICA_SANTIAGO;
        case 'America/Santo_Domingo': return TimezoneEnum.AMERICA_SANTO_DOMINGO;
        case 'America/Sao_Paulo': return TimezoneEnum.AMERICA_SAO_PAULO;
        case 'America/Scoresbysund': return TimezoneEnum.AMERICA_SCORESBYSUND;
        case 'America/Sitka': return TimezoneEnum.AMERICA_SITKA;
        case 'America/St_Barthelemy': return TimezoneEnum.AMERICA_ST_BARTHELEMY;
        case 'America/St_Johns': return TimezoneEnum.AMERICA_ST_JOHNS;
        case 'America/St_Kitts': return TimezoneEnum.AMERICA_ST_KITTS;
        case 'America/St_Lucia': return TimezoneEnum.AMERICA_ST_LUCIA;
        case 'America/St_Thomas': return TimezoneEnum.AMERICA_ST_THOMAS;
        case 'America/St_Vincent': return TimezoneEnum.AMERICA_ST_VINCENT;
        case 'America/Swift_Current': return TimezoneEnum.AMERICA_SWIFT_CURRENT;
        case 'America/Tegucigalpa': return TimezoneEnum.AMERICA_TEGUCIGALPA;
        case 'America/Thule': return TimezoneEnum.AMERICA_THULE;
        case 'America/Tijuana': return TimezoneEnum.AMERICA_TIJUANA;
        case 'America/Toronto': return TimezoneEnum.AMERICA_TORONTO;
        case 'America/Tortola': return TimezoneEnum.AMERICA_TORTOLA;
        case 'America/Vancouver': return TimezoneEnum.AMERICA_VANCOUVER;
        case 'America/Whitehorse': return TimezoneEnum.AMERICA_WHITEHORSE;
        case 'America/Winnipeg': return TimezoneEnum.AMERICA_WINNIPEG;
        case 'America/Yakutat': return TimezoneEnum.AMERICA_YAKUTAT;
        case 'Antarctica/Casey': return TimezoneEnum.ANTARCTICA_CASEY;
        case 'Antarctica/Davis': return TimezoneEnum.ANTARCTICA_DAVIS;
        case 'Antarctica/DumontDUrville': return TimezoneEnum.ANTARCTICA_DUMONTDURVILLE;
        case 'Antarctica/Macquarie': return TimezoneEnum.ANTARCTICA_MACQUARIE;
        case 'Antarctica/Mawson': return TimezoneEnum.ANTARCTICA_MAWSON;
        case 'Antarctica/McMurdo': return TimezoneEnum.ANTARCTICA_MCMURDO;
        case 'Antarctica/Palmer': return TimezoneEnum.ANTARCTICA_PALMER;
        case 'Antarctica/Rothera': return TimezoneEnum.ANTARCTICA_ROTHERA;
        case 'Antarctica/Syowa': return TimezoneEnum.ANTARCTICA_SYOWA;
        case 'Antarctica/Troll': return TimezoneEnum.ANTARCTICA_TROLL;
        case 'Antarctica/Vostok': return TimezoneEnum.ANTARCTICA_VOSTOK;
        case 'Arctic/Longyearbyen': return TimezoneEnum.ARCTIC_LONGYEARBYEN;
        case 'Asia/Aden': return TimezoneEnum.ASIA_ADEN;
        case 'Asia/Almaty': return TimezoneEnum.ASIA_ALMATY;
        case 'Asia/Amman': return TimezoneEnum.ASIA_AMMAN;
        case 'Asia/Anadyr': return TimezoneEnum.ASIA_ANADYR;
        case 'Asia/Aqtau': return TimezoneEnum.ASIA_AQTAU;
        case 'Asia/Aqtobe': return TimezoneEnum.ASIA_AQTOBE;
        case 'Asia/Ashgabat': return TimezoneEnum.ASIA_ASHGABAT;
        case 'Asia/Atyrau': return TimezoneEnum.ASIA_ATYRAU;
        case 'Asia/Baghdad': return TimezoneEnum.ASIA_BAGHDAD;
        case 'Asia/Bahrain': return TimezoneEnum.ASIA_BAHRAIN;
        case 'Asia/Baku': return TimezoneEnum.ASIA_BAKU;
        case 'Asia/Bangkok': return TimezoneEnum.ASIA_BANGKOK;
        case 'Asia/Barnaul': return TimezoneEnum.ASIA_BARNAUL;
        case 'Asia/Beirut': return TimezoneEnum.ASIA_BEIRUT;
        case 'Asia/Bishkek': return TimezoneEnum.ASIA_BISHKEK;
        case 'Asia/Brunei': return TimezoneEnum.ASIA_BRUNEI;
        case 'Asia/Chita': return TimezoneEnum.ASIA_CHITA;
        case 'Asia/Choibalsan': return TimezoneEnum.ASIA_CHOIBALSAN;
        case 'Asia/Colombo': return TimezoneEnum.ASIA_COLOMBO;
        case 'Asia/Damascus': return TimezoneEnum.ASIA_DAMASCUS;
        case 'Asia/Dhaka': return TimezoneEnum.ASIA_DHAKA;
        case 'Asia/Dili': return TimezoneEnum.ASIA_DILI;
        case 'Asia/Dubai': return TimezoneEnum.ASIA_DUBAI;
        case 'Asia/Dushanbe': return TimezoneEnum.ASIA_DUSHANBE;
        case 'Asia/Famagusta': return TimezoneEnum.ASIA_FAMAGUSTA;
        case 'Asia/Gaza': return TimezoneEnum.ASIA_GAZA;
        case 'Asia/Hebron': return TimezoneEnum.ASIA_HEBRON;
        case 'Asia/Ho_Chi_Minh': return TimezoneEnum.ASIA_HO_CHI_MINH;
        case 'Asia/Hong_Kong': return TimezoneEnum.ASIA_HONG_KONG;
        case 'Asia/Hovd': return TimezoneEnum.ASIA_HOVD;
        case 'Asia/Irkutsk': return TimezoneEnum.ASIA_IRKUTSK;
        case 'Asia/Jakarta': return TimezoneEnum.ASIA_JAKARTA;
        case 'Asia/Jayapura': return TimezoneEnum.ASIA_JAYAPURA;
        case 'Asia/Jerusalem': return TimezoneEnum.ASIA_JERUSALEM;
        case 'Asia/Kabul': return TimezoneEnum.ASIA_KABUL;
        case 'Asia/Kamchatka': return TimezoneEnum.ASIA_KAMCHATKA;
        case 'Asia/Karachi': return TimezoneEnum.ASIA_KARACHI;
        case 'Asia/Kathmandu': return TimezoneEnum.ASIA_KATHMANDU;
        case 'Asia/Khandyga': return TimezoneEnum.ASIA_KHANDYGA;
        case 'Asia/Kolkata': return TimezoneEnum.ASIA_KOLKATA;
        case 'Asia/Krasnoyarsk': return TimezoneEnum.ASIA_KRASNOYARSK;
        case 'Asia/Kuala_Lumpur': return TimezoneEnum.ASIA_KUALA_LUMPUR;
        case 'Asia/Kuching': return TimezoneEnum.ASIA_KUCHING;
        case 'Asia/Kuwait': return TimezoneEnum.ASIA_KUWAIT;
        case 'Asia/Macau': return TimezoneEnum.ASIA_MACAU;
        case 'Asia/Magadan': return TimezoneEnum.ASIA_MAGADAN;
        case 'Asia/Makassar': return TimezoneEnum.ASIA_MAKASSAR;
        case 'Asia/Manila': return TimezoneEnum.ASIA_MANILA;
        case 'Asia/Muscat': return TimezoneEnum.ASIA_MUSCAT;
        case 'Asia/Nicosia': return TimezoneEnum.ASIA_NICOSIA;
        case 'Asia/Novokuznetsk': return TimezoneEnum.ASIA_NOVOKUZNETSK;
        case 'Asia/Novosibirsk': return TimezoneEnum.ASIA_NOVOSIBIRSK;
        case 'Asia/Omsk': return TimezoneEnum.ASIA_OMSK;
        case 'Asia/Oral': return TimezoneEnum.ASIA_ORAL;
        case 'Asia/Phnom_Penh': return TimezoneEnum.ASIA_PHNOM_PENH;
        case 'Asia/Pontianak': return TimezoneEnum.ASIA_PONTIANAK;
        case 'Asia/Pyongyang': return TimezoneEnum.ASIA_PYONGYANG;
        case 'Asia/Qatar': return TimezoneEnum.ASIA_QATAR;
        case 'Asia/Qostanay': return TimezoneEnum.ASIA_QOSTANAY;
        case 'Asia/Qyzylorda': return TimezoneEnum.ASIA_QYZYLORDA;
        case 'Asia/Riyadh': return TimezoneEnum.ASIA_RIYADH;
        case 'Asia/Sakhalin': return TimezoneEnum.ASIA_SAKHALIN;
        case 'Asia/Samarkand': return TimezoneEnum.ASIA_SAMARKAND;
        case 'Asia/Seoul': return TimezoneEnum.ASIA_SEOUL;
        case 'Asia/Shanghai': return TimezoneEnum.ASIA_SHANGHAI;
        case 'Asia/Singapore': return TimezoneEnum.ASIA_SINGAPORE;
        case 'Asia/Srednekolymsk': return TimezoneEnum.ASIA_SREDNEKOLYMSK;
        case 'Asia/Taipei': return TimezoneEnum.ASIA_TAIPEI;
        case 'Asia/Tashkent': return TimezoneEnum.ASIA_TASHKENT;
        case 'Asia/Tbilisi': return TimezoneEnum.ASIA_TBILISI;
        case 'Asia/Tehran': return TimezoneEnum.ASIA_TEHRAN;
        case 'Asia/Thimphu': return TimezoneEnum.ASIA_THIMPHU;
        case 'Asia/Tokyo': return TimezoneEnum.ASIA_TOKYO;
        case 'Asia/Tomsk': return TimezoneEnum.ASIA_TOMSK;
        case 'Asia/Ulaanbaatar': return TimezoneEnum.ASIA_ULAANBAATAR;
        case 'Asia/Urumqi': return TimezoneEnum.ASIA_URUMQI;
        case 'Asia/Ust-Nera': return TimezoneEnum.ASIA_UST_NERA;
        case 'Asia/Vientiane': return TimezoneEnum.ASIA_VIENTIANE;
        case 'Asia/Vladivostok': return TimezoneEnum.ASIA_VLADIVOSTOK;
        case 'Asia/Yakutsk': return TimezoneEnum.ASIA_YAKUTSK;
        case 'Asia/Yangon': return TimezoneEnum.ASIA_YANGON;
        case 'Asia/Yekaterinburg': return TimezoneEnum.ASIA_YEKATERINBURG;
        case 'Asia/Yerevan': return TimezoneEnum.ASIA_YEREVAN;
        case 'Atlantic/Azores': return TimezoneEnum.ATLANTIC_AZORES;
        case 'Atlantic/Bermuda': return TimezoneEnum.ATLANTIC_BERMUDA;
        case 'Atlantic/Canary': return TimezoneEnum.ATLANTIC_CANARY;
        case 'Atlantic/Cape_Verde': return TimezoneEnum.ATLANTIC_CAPE_VERDE;
        case 'Atlantic/Faroe': return TimezoneEnum.ATLANTIC_FAROE;
        case 'Atlantic/Madeira': return TimezoneEnum.ATLANTIC_MADEIRA;
        case 'Atlantic/Reykjavik': return TimezoneEnum.ATLANTIC_REYKJAVIK;
        case 'Atlantic/South_Georgia': return TimezoneEnum.ATLANTIC_SOUTH_GEORGIA;
        case 'Atlantic/St_Helena': return TimezoneEnum.ATLANTIC_ST_HELENA;
        case 'Atlantic/Stanley': return TimezoneEnum.ATLANTIC_STANLEY;
        case 'Australia/Adelaide': return TimezoneEnum.AUSTRALIA_ADELAIDE;
        case 'Australia/Brisbane': return TimezoneEnum.AUSTRALIA_BRISBANE;
        case 'Australia/Broken_Hill': return TimezoneEnum.AUSTRALIA_BROKEN_HILL;
        case 'Australia/Darwin': return TimezoneEnum.AUSTRALIA_DARWIN;
        case 'Australia/Eucla': return TimezoneEnum.AUSTRALIA_EUCLA;
        case 'Australia/Hobart': return TimezoneEnum.AUSTRALIA_HOBART;
        case 'Australia/Lindeman': return TimezoneEnum.AUSTRALIA_LINDEMAN;
        case 'Australia/Lord_Howe': return TimezoneEnum.AUSTRALIA_LORD_HOWE;
        case 'Australia/Melbourne': return TimezoneEnum.AUSTRALIA_MELBOURNE;
        case 'Australia/Perth': return TimezoneEnum.AUSTRALIA_PERTH;
        case 'Australia/Sydney': return TimezoneEnum.AUSTRALIA_SYDNEY;
        case 'Europe/Amsterdam': return TimezoneEnum.EUROPE_AMSTERDAM;
        case 'Europe/Andorra': return TimezoneEnum.EUROPE_ANDORRA;
        case 'Europe/Astrakhan': return TimezoneEnum.EUROPE_ASTRAKHAN;
        case 'Europe/Athens': return TimezoneEnum.EUROPE_ATHENS;
        case 'Europe/Belgrade': return TimezoneEnum.EUROPE_BELGRADE;
        case 'Europe/Berlin': return TimezoneEnum.EUROPE_BERLIN;
        case 'Europe/Bratislava': return TimezoneEnum.EUROPE_BRATISLAVA;
        case 'Europe/Brussels': return TimezoneEnum.EUROPE_BRUSSELS;
        case 'Europe/Bucharest': return TimezoneEnum.EUROPE_BUCHAREST;
        case 'Europe/Budapest': return TimezoneEnum.EUROPE_BUDAPEST;
        case 'Europe/Busingen': return TimezoneEnum.EUROPE_BUSINGEN;
        case 'Europe/Chisinau': return TimezoneEnum.EUROPE_CHISINAU;
        case 'Europe/Copenhagen': return TimezoneEnum.EUROPE_COPENHAGEN;
        case 'Europe/Dublin': return TimezoneEnum.EUROPE_DUBLIN;
        case 'Europe/Gibraltar': return TimezoneEnum.EUROPE_GIBRALTAR;
        case 'Europe/Guernsey': return TimezoneEnum.EUROPE_GUERNSEY;
        case 'Europe/Helsinki': return TimezoneEnum.EUROPE_HELSINKI;
        case 'Europe/Isle_of_Man': return TimezoneEnum.EUROPE_ISLE_OF_MAN;
        case 'Europe/Istanbul': return TimezoneEnum.EUROPE_ISTANBUL;
        case 'Europe/Jersey': return TimezoneEnum.EUROPE_JERSEY;
        case 'Europe/Kaliningrad': return TimezoneEnum.EUROPE_KALININGRAD;
        case 'Europe/Kirov': return TimezoneEnum.EUROPE_KIROV;
        case 'Europe/Kyiv': return TimezoneEnum.EUROPE_KYIV;
        case 'Europe/Lisbon': return TimezoneEnum.EUROPE_LISBON;
        case 'Europe/Ljubljana': return TimezoneEnum.EUROPE_LJUBLJANA;
        case 'Europe/London': return TimezoneEnum.EUROPE_LONDON;
        case 'Europe/Luxembourg': return TimezoneEnum.EUROPE_LUXEMBOURG;
        case 'Europe/Madrid': return TimezoneEnum.EUROPE_MADRID;
        case 'Europe/Malta': return TimezoneEnum.EUROPE_MALTA;
        case 'Europe/Mariehamn': return TimezoneEnum.EUROPE_MARIEHAMN;
        case 'Europe/Minsk': return TimezoneEnum.EUROPE_MINSK;
        case 'Europe/Monaco': return TimezoneEnum.EUROPE_MONACO;
        case 'Europe/Moscow': return TimezoneEnum.EUROPE_MOSCOW;
        case 'Europe/Oslo': return TimezoneEnum.EUROPE_OSLO;
        case 'Europe/Paris': return TimezoneEnum.EUROPE_PARIS;
        case 'Europe/Podgorica': return TimezoneEnum.EUROPE_PODGORICA;
        case 'Europe/Prague': return TimezoneEnum.EUROPE_PRAGUE;
        case 'Europe/Riga': return TimezoneEnum.EUROPE_RIGA;
        case 'Europe/Rome': return TimezoneEnum.EUROPE_ROME;
        case 'Europe/Samara': return TimezoneEnum.EUROPE_SAMARA;
        case 'Europe/San_Marino': return TimezoneEnum.EUROPE_SAN_MARINO;
        case 'Europe/Sarajevo': return TimezoneEnum.EUROPE_SARAJEVO;
        case 'Europe/Saratov': return TimezoneEnum.EUROPE_SARATOV;
        case 'Europe/Simferopol': return TimezoneEnum.EUROPE_SIMFEROPOL;
        case 'Europe/Skopje': return TimezoneEnum.EUROPE_SKOPJE;
        case 'Europe/Sofia': return TimezoneEnum.EUROPE_SOFIA;
        case 'Europe/Stockholm': return TimezoneEnum.EUROPE_STOCKHOLM;
        case 'Europe/Tallinn': return TimezoneEnum.EUROPE_TALLINN;
        case 'Europe/Tirane': return TimezoneEnum.EUROPE_TIRANE;
        case 'Europe/Ulyanovsk': return TimezoneEnum.EUROPE_ULYANOVSK;
        case 'Europe/Vaduz': return TimezoneEnum.EUROPE_VADUZ;
        case 'Europe/Vatican': return TimezoneEnum.EUROPE_VATICAN;
        case 'Europe/Vienna': return TimezoneEnum.EUROPE_VIENNA;
        case 'Europe/Vilnius': return TimezoneEnum.EUROPE_VILNIUS;
        case 'Europe/Volgograd': return TimezoneEnum.EUROPE_VOLGOGRAD;
        case 'Europe/Warsaw': return TimezoneEnum.EUROPE_WARSAW;
        case 'Europe/Zagreb': return TimezoneEnum.EUROPE_ZAGREB;
        case 'Europe/Zurich': return TimezoneEnum.EUROPE_ZURICH;
        case 'Indian/Antananarivo': return TimezoneEnum.INDIAN_ANTANANARIVO;
        case 'Indian/Chagos': return TimezoneEnum.INDIAN_CHAGOS;
        case 'Indian/Christmas': return TimezoneEnum.INDIAN_CHRISTMAS;
        case 'Indian/Cocos': return TimezoneEnum.INDIAN_COCOS;
        case 'Indian/Comoro': return TimezoneEnum.INDIAN_COMORO;
        case 'Indian/Kerguelen': return TimezoneEnum.INDIAN_KERGUELEN;
        case 'Indian/Mahe': return TimezoneEnum.INDIAN_MAHE;
        case 'Indian/Maldives': return TimezoneEnum.INDIAN_MALDIVES;
        case 'Indian/Mauritius': return TimezoneEnum.INDIAN_MAURITIUS;
        case 'Indian/Mayotte': return TimezoneEnum.INDIAN_MAYOTTE;
        case 'Indian/Reunion': return TimezoneEnum.INDIAN_REUNION;
        case 'Pacific/Apia': return TimezoneEnum.PACIFIC_APIA;
        case 'Pacific/Auckland': return TimezoneEnum.PACIFIC_AUCKLAND;
        case 'Pacific/Bougainville': return TimezoneEnum.PACIFIC_BOUGAINVILLE;
        case 'Pacific/Chatham': return TimezoneEnum.PACIFIC_CHATHAM;
        case 'Pacific/Chuuk': return TimezoneEnum.PACIFIC_CHUUK;
        case 'Pacific/Easter': return TimezoneEnum.PACIFIC_EASTER;
        case 'Pacific/Efate': return TimezoneEnum.PACIFIC_EFATE;
        case 'Pacific/Fakaofo': return TimezoneEnum.PACIFIC_FAKAOFO;
        case 'Pacific/Fiji': return TimezoneEnum.PACIFIC_FIJI;
        case 'Pacific/Funafuti': return TimezoneEnum.PACIFIC_FUNAFUTI;
        case 'Pacific/Galapagos': return TimezoneEnum.PACIFIC_GALAPAGOS;
        case 'Pacific/Gambier': return TimezoneEnum.PACIFIC_GAMBIER;
        case 'Pacific/Guadalcanal': return TimezoneEnum.PACIFIC_GUADALCANAL;
        case 'Pacific/Guam': return TimezoneEnum.PACIFIC_GUAM;
        case 'Pacific/Honolulu': return TimezoneEnum.PACIFIC_HONOLULU;
        case 'Pacific/Kanton': return TimezoneEnum.PACIFIC_KANTON;
        case 'Pacific/Kiritimati': return TimezoneEnum.PACIFIC_KIRITIMATI;
        case 'Pacific/Kosrae': return TimezoneEnum.PACIFIC_KOSRAE;
        case 'Pacific/Kwajalein': return TimezoneEnum.PACIFIC_KWAJALEIN;
        case 'Pacific/Majuro': return TimezoneEnum.PACIFIC_MAJURO;
        case 'Pacific/Marquesas': return TimezoneEnum.PACIFIC_MARQUESAS;
        case 'Pacific/Midway': return TimezoneEnum.PACIFIC_MIDWAY;
        case 'Pacific/Nauru': return TimezoneEnum.PACIFIC_NAURU;
        case 'Pacific/Niue': return TimezoneEnum.PACIFIC_NIUE;
        case 'Pacific/Norfolk': return TimezoneEnum.PACIFIC_NORFOLK;
        case 'Pacific/Noumea': return TimezoneEnum.PACIFIC_NOUMEA;
        case 'Pacific/Pago_Pago': return TimezoneEnum.PACIFIC_PAGO_PAGO;
        case 'Pacific/Palau': return TimezoneEnum.PACIFIC_PALAU;
        case 'Pacific/Pitcairn': return TimezoneEnum.PACIFIC_PITCAIRN;
        case 'Pacific/Pohnpei': return TimezoneEnum.PACIFIC_POHNPEI;
        case 'Pacific/Port_Moresby': return TimezoneEnum.PACIFIC_PORT_MORESBY;
        case 'Pacific/Rarotonga': return TimezoneEnum.PACIFIC_RAROTONGA;
        case 'Pacific/Saipan': return TimezoneEnum.PACIFIC_SAIPAN;
        case 'Pacific/Tahiti': return TimezoneEnum.PACIFIC_TAHITI;
        case 'Pacific/Tarawa': return TimezoneEnum.PACIFIC_TARAWA;
        case 'Pacific/Tongatapu': return TimezoneEnum.PACIFIC_TONGATAPU;
        case 'Pacific/Wake': return TimezoneEnum.PACIFIC_WAKE;
        case 'Pacific/Wallis': return TimezoneEnum.PACIFIC_WALLIS;
        case 'UTC':
        default:
            return TimezoneEnum.UTC;
    }
};
export const getAllTimezoneEnumOptions = () => Object.values(TimezoneEnum);
