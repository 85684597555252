// TS copy of import 'togglePasswordVisibility' from "@core/mixins/ui/forms";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
let Toast = class Toast extends Vue {
    successToast(title, icon) {
        if (title !== '') {
            this.show(title, icon || 'EditIcon', 'success');
        }
    }
    errorToast(title, icon) {
        if (title !== '') {
            this.show(title, icon || 'CloudLightningIcon', 'danger');
        }
    }
    show(title, icon, variant) {
        this.$toast({
            component: ToastificationContent,
            props: {
                title, icon, variant,
            },
        });
    }
};
Toast = __decorate([
    Component
], Toast);
export default Toast;
