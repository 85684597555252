import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/administration/email-parser',
        name: 'administration-email-parser',
        component: () => import('@/views/administration/email-parser/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.INBOUND_EMAIL_VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.INBOUND_EMAIL_VIEW),
            pageTitle: 'pageTitles.emailParser.list',
            goBackRoute: 'home',
            breadcrumb: [
                {
                    text: 'breadcrumbs.emailParser.list.emailParser',
                },
                {
                    text: 'breadcrumbs.emailParser.list.list',
                    active: true,
                },
            ],
        },
    },
];
