import { DefaultErrorHandler, HttpClientWrapper, SuccessApiOperationResult } from '@/service/api/client';
const ANALYTIC_ENDPOINT = `${process.env.VUE_APP_API_URL}/api/analytic-api/v1`;
// eslint-disable-next-line import/prefer-default-export
export const analyticApi = (store) => HttpClientWrapper.defaultInstance(store, { baseUrl: ANALYTIC_ENDPOINT });
// eslint-disable-next-line import/prefer-default-export
export class AnalyticApiService {
    // eslint-disable-next-line no-unused-vars,no-empty-function,no-useless-constructor
    constructor(store) {
        this.store = store;
    }
    async getOrganisationUpload(orgUuid, uploadUuid) {
        try {
            const httpClient = analyticApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploadTopOffersVsB2bMarketPrice(orgUuid, uploadUuid, queryParams, pageQuery) {
        try {
            const httpClient = analyticApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}/top-offers-vs-b2b-market-price`, { params: { ...queryParams, ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploadIncreasedOffers(orgUuid, uploadUuid, queryParams, pageQuery) {
        try {
            const httpClient = analyticApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}/increased-offers`, { params: { ...queryParams, ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploadDecreasedOffers(orgUuid, uploadUuid, queryParams, pageQuery) {
        try {
            const httpClient = analyticApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}/decreased-offers`, { params: { ...queryParams, ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploadTailedOffers(orgUuid, uploadUuid, queryParams, pageQuery) {
        try {
            const httpClient = analyticApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}/tailed-offers`, { params: { ...queryParams, ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationVintageProductAveragePerMonth(orgUuid, vintageProductUuid) {
        try {
            const httpClient = analyticApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintage-products/${vintageProductUuid}/average-per-month`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
}
