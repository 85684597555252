var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
// Create a new Vue instance as the event bus
const eventBus = new Vue();
// Define the event bus mixin
// eslint-disable-next-line import/prefer-default-export
let EventBus = class EventBus extends Vue {
    // Method to emit events on the event bus
    // eslint-disable-next-line class-methods-use-this
    $emitEvent(eventName, payload) {
        eventBus.$emit(eventName, payload);
    }
    // Method to listen to events on the event bus
    // eslint-disable-next-line class-methods-use-this
    $onEvent(eventName, callback) {
        eventBus.$on(eventName, callback);
    }
    // Method to stop listening to events on the event bus
    // eslint-disable-next-line class-methods-use-this
    $offEvent(eventName, callback) {
        eventBus.$off(eventName, callback);
    }
};
EventBus = __decorate([
    Component
], EventBus);
export default EventBus;
