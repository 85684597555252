import { handleOrganisationRoutes } from '@/helpers/router';
import { DEFAULT_ABILITIES } from '@/helpers/auth';
export default [
    {
        path: '/organisation/:orgUuid/organisations/:orgUuidForDetails',
        name: 'organisation-organisations-details',
        component: () => import('@/views/organisation/organisations/Show.vue'),
        meta: {
            resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
            action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
            pageTitle: 'pageTitles.organisationOrganisations.details',
            goBackRoute: 'home',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisationOrganisations.organisationOrganisationsDetails.organisations',
                },
                {
                    text: 'breadcrumbs.organisationOrganisations.organisationOrganisationsDetails.details',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
