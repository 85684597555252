// eslint-disable-next-line no-shadow
export var PermissionSuffix;
(function (PermissionSuffix) {
    PermissionSuffix["USER"] = "";
    PermissionSuffix["USER_ORGANISATION"] = "user_org_";
    PermissionSuffix["ORGANISATION"] = "org_";
})(PermissionSuffix || (PermissionSuffix = {}));
export const permissionComponentTranslationToken = (permissionComponent) => `enums.permission.${permissionComponent}.component`;
export const permissionActionTranslationToken = (permissionComponent, permissionAction) => `enums.permission.${permissionComponent}.actions.${permissionAction}`;
export const permissionComponentName = (id) => id.toString().split('-')[0];
export const permissionActionName = (id) => id.toString().split('-')[1];
export const permissionSubjectName = (id, permissionSuffix) => {
    const subject = permissionComponentName(id);
    return `${permissionSuffix}${subject}`;
};
export const groupPermissionsPerComponent = (permissions) => permissions.reduce((grouped, permission) => {
    const key = permissionComponentName(permission.id);
    const value = permissionActionName(permission.id);
    if (!grouped[key]) {
        // eslint-disable-next-line no-param-reassign
        grouped[key] = [];
    }
    grouped[key].push(value);
    return grouped;
}, {});
