import { EnvironmentEnum } from '@vini-wine/core-enums';
export const environment = (() => {
    switch (process.env.VUE_APP_ENV) {
        case EnvironmentEnum.PRODUCTION:
            return EnvironmentEnum.PRODUCTION;
        case EnvironmentEnum.TESTING:
            return EnvironmentEnum.TESTING;
        default:
            return EnvironmentEnum.LOCAL;
    }
})();
export function isLocalEnvironment() {
    return environment === EnvironmentEnum.LOCAL;
}
export function isTestingEnvironment() {
    return environment === EnvironmentEnum.TESTING;
}
export function isProductionEnvironment() {
    return process.env.VUE_APP_ENV === EnvironmentEnum.PRODUCTION;
}
export function getXsrfCookieName() {
    switch (environment) {
        case EnvironmentEnum.TESTING:
            return 'TESTING-XSRF-TOKEN';
        default:
            return 'XSRF-TOKEN';
    }
}
