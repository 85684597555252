import { OfferApiService } from '@/service/api/offer/v1/api';
import { AdminApiService } from '@/service/api/admin/v1/api';
import { ContentApiService } from '@/service/api/content/v1/api';
import { MappedExternal1ApiService } from '@/service/api/mappedExternal1/v1/api';
import { AnalyticApiService } from '@/service/api/analytic/v1/api';
export default (store) => ({
    mappedExternal1Api: new MappedExternal1ApiService(store),
    offerApi: new OfferApiService(store),
    adminApi: new AdminApiService(store),
    contentApi: new ContentApiService(store),
    analyticApi: new AnalyticApiService(store),
});
