import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/settings/details',
        name: 'organisation-settings',
        component: () => import('@/views/organisation/settings/OrganisationSettings.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.ORGANISATION__EDIT, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.ORGANISATION__EDIT),
            pageTitle: 'pageTitles.settings.organisation',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisationSettings.settings',
                },
                {
                    text: 'breadcrumbs.organisationSettings.details',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/settings/plan',
        name: 'organisation-plan',
        component: () => import('@/views/organisation/settings/Plan.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.PRICING_PLAN__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.PRICING_PLAN__VIEW),
            pageTitle: 'pageTitles.settings.plan',
            breadcrumb: [
                {
                    text: 'breadcrumbs.planSettings.settings',
                },
                {
                    text: 'breadcrumbs.planSettings.plan',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/settings/email-parser',
        name: 'organisation-email-parser',
        component: () => import('@/views/organisation/settings/EmailParser.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.ORGANISATION_SETTINGS__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.ORGANISATION_SETTINGS__VIEW),
            pageTitle: 'pageTitles.settings.emailParser',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisationEmailParser.settings',
                },
                {
                    text: 'breadcrumbs.organisationEmailParser.emailParser',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
