import { CurrencyCodeEnum } from '@vini-wine/core-enums';
export const numberFormats = {
    [CurrencyCodeEnum.EUR]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.EUR,
        },
    },
    [CurrencyCodeEnum.GBP]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.GBP,
        },
    },
    [CurrencyCodeEnum.USD]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.USD,
        },
    },
    [CurrencyCodeEnum.HKD]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.HKD,
        },
    },
    [CurrencyCodeEnum.JPY]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.JPY,
        },
    },
    [CurrencyCodeEnum.AUD]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.AUD,
        },
    },
    [CurrencyCodeEnum.CAD]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.CAD,
        },
    },
    [CurrencyCodeEnum.CHF]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.CHF,
        },
    },
    [CurrencyCodeEnum.CNY]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.CNY,
        },
    },
    [CurrencyCodeEnum.NZD]: {
        currency: {
            style: 'currency',
            currency: CurrencyCodeEnum.NZD,
        },
    },
};
export const currencyFlag = (code) => {
    switch (code) {
        case CurrencyCodeEnum.EUR:
            return 'eu';
        case CurrencyCodeEnum.GBP:
            return 'gb';
        case CurrencyCodeEnum.USD:
            return 'us';
        case CurrencyCodeEnum.HKD:
            return 'hk';
        case CurrencyCodeEnum.JPY:
            return 'jp';
        case CurrencyCodeEnum.AUD:
            return 'au';
        case CurrencyCodeEnum.CAD:
            return 'ca';
        case CurrencyCodeEnum.CHF:
            return 'ch';
        case CurrencyCodeEnum.CNY:
            return 'cn';
        case CurrencyCodeEnum.NZD:
            return 'nz';
        default:
            return null;
    }
};
export const createCurrencyCodeFromString = (symbol) => {
    const symbolUpperCase = String(symbol).toUpperCase();
    switch (symbolUpperCase) {
        case '€':
        case 'EUR':
            return CurrencyCodeEnum.EUR;
        case '£':
        case 'GBP':
            return CurrencyCodeEnum.GBP;
        case '$':
        case 'USD':
            return CurrencyCodeEnum.USD;
        case 'HK$':
        case 'HKD':
            return CurrencyCodeEnum.HKD;
        case 'JP¥':
        case 'JPY':
            return CurrencyCodeEnum.JPY;
        case 'A$':
        case 'AUD':
            return CurrencyCodeEnum.AUD;
        case 'C$':
        case 'CAD':
            return CurrencyCodeEnum.CAD;
        case 'CHF':
            return CurrencyCodeEnum.CHF;
        case 'CN¥':
        case 'CNY':
            return CurrencyCodeEnum.CNY;
        case 'NZ$':
        case 'NZD':
            return CurrencyCodeEnum.NZD;
        default:
            return null;
    }
};
export const getSupportedCurrencyCodes = () => {
    const currencyCodes = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const code in CurrencyCodeEnum) {
        if (Object.prototype.hasOwnProperty.call(CurrencyCodeEnum, code)) {
            // @ts-ignore
            currencyCodes.push(CurrencyCodeEnum[code]);
        }
    }
    return currencyCodes;
};
