// eslint-disable-next-line import/no-cycle
import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/integrations/exact',
        name: 'integrations-exact-create',
        component: () => import('@/views/organisation/integrations/exact/Wizard.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.INTEGRATION_EXACT__CREATE, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.INTEGRATION_EXACT__CREATE),
            pageTitle: 'pageTitles.exactIntegration.title',
            disableScrollBehaviour: true,
            breadcrumb: [
                {
                    text: 'breadcrumbs.integrations.exact.integrations',
                },
                {
                    text: 'breadcrumbs.integrations.exact.exact',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
