var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component, { mixins } from 'vue-class-component';
import { EnvironmentEnum } from '@vini-wine/core-enums';
import { environment } from '@/helpers/environment';
// eslint-disable-next-line no-use-before-define
let ViniCookies = class ViniCookies extends mixins() {
    // eslint-disable-next-line class-methods-use-this
    get cookiePrefix() {
        switch (environment) {
            case EnvironmentEnum.PRODUCTION:
            case EnvironmentEnum.LOCAL:
                return '';
            default:
                return `${environment}-`;
        }
    }
    setCookie(cookie, value) {
        const cookieName = `${this.cookiePrefix}${cookie}`;
        this.$cookies.set(cookieName, value);
    }
    getCookie(cookie) {
        const cookieName = `${this.cookiePrefix}${cookie}`;
        return this.$cookies.get(cookieName);
    }
    removeCookie(cookie) {
        const cookieName = `${this.cookiePrefix}${cookie}`;
        this.$cookies.remove(cookieName);
    }
};
ViniCookies = __decorate([
    Component({})
], ViniCookies);
export default ViniCookies;
