import moment from 'moment-timezone';
import { DateTimeFormatEnum } from '@vini-wine/core-enums';
export const getDateTimeFormat = (format) => {
    switch (format) {
        case DateTimeFormatEnum.FORMAT_2:
            return 'YY-M-D H:mm:ss'; // YMD - minified
        case DateTimeFormatEnum.FORMAT_3:
            return 'D-M-YYYY HH:mm:ss'; // DMY
        case DateTimeFormatEnum.FORMAT_4:
            return 'D-M-YY H:mm:ss'; // DMY - minified
        case DateTimeFormatEnum.FORMAT_5:
            return 'MM-DD-YYYY HH:mm:ss'; // MDY
        case DateTimeFormatEnum.FORMAT_6:
            return 'M-D-YY H:mm:ss'; // MDY - minified
        case DateTimeFormatEnum.FORMAT_1:
        default:
            return 'YYYY-MM-DD HH:mm:ss'; // Handle any other cases as needed
    }
};
export const getDateFormat = (format) => {
    switch (format) {
        case DateTimeFormatEnum.FORMAT_2:
            return 'YY-M-D'; // YMD - minified
        case DateTimeFormatEnum.FORMAT_3:
            return 'D-M-YYYY'; // DMY
        case DateTimeFormatEnum.FORMAT_4:
            return 'j-n-y'; // DMY - minified
        case DateTimeFormatEnum.FORMAT_5:
            return 'MM-DD-YYYY'; // MDY
        case DateTimeFormatEnum.FORMAT_6:
            return 'M-D-YY'; // MDY - minified
        case DateTimeFormatEnum.FORMAT_1:
        default:
            return 'YYYY-MM-DD'; // Handle any other cases as needed
    }
};
export const getAllDateTimeFormatEnumOptions = () => Object.values(DateTimeFormatEnum).map(value => ({
    value,
    label: moment().format(getDateTimeFormat(value)),
}));
