// eslint-disable-next-line import/no-cycle
import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/offer-alerts',
        name: 'offer-alerts-list',
        component: () => import('@/views/organisation/offer-alerts/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.OFFER_ALERT__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.OFFER_ALERT__VIEW),
            pageTitle: 'pageTitles.offerAlerts.list',
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
