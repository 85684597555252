import { DEFAULT_ABILITIES } from '@/helpers/auth';
export default [
    {
        path: '/account/settings',
        name: 'account-settings',
        component: () => import('@/views/account/AccountSettings.vue'),
        meta: {
            pageTitle: 'pageTitles.settings.account',
            resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
            action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
            breadcrumb: [
                {
                    text: 'breadcrumbs.accountSettings.account',
                },
                {
                    text: 'breadcrumbs.accountSettings.settings',
                    active: true,
                },
            ],
        },
    },
];
