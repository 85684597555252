import axios from 'axios';
import store, { ns } from '@/store';
import { ADMIN_STATE_MUTATIONS, AdminStore } from '@/store/admin';
import { getXsrfCookieName } from '@/helpers/environment';
import Vue from 'vue';
import { AdminApiService } from '@/service/api/admin/v1/api';
import router from '@/router';
import { StatusCodeEnum, ApiOperationStatusEnum, CookieEnum } from '@vini-wine/core-enums';
const adminApiService = new AdminApiService(Vue.prototype.store);
export class HttpClientWrapper {
    constructor(config) {
        this._axiosInstance = axios.create({
            withCredentials: true,
            baseURL: config.baseUrl,
            headers: {
                'Accept-Language': localStorage.getItem(CookieEnum.SELECTED_LOCALE),
            },
            xsrfCookieName: getXsrfCookieName(),
        });
        this._axiosInstance.interceptors.response.use(response => response, async (error) => {
            if (!error.response)
                return;
            if (error.response.status === StatusCodeEnum.SERVICE_UNAVAILABLE) {
                if (router.currentRoute.name !== 'error-503') {
                    await router.push({ name: 'error-503' });
                }
                return;
            }
            if (error.response.status === StatusCodeEnum.UNAUTHORIZED) {
                // Handle unauthenticated error
                store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_OUT));
                // remove the selected organisation cookie
                Vue.$cookies.remove(CookieEnum.SELECTED_ORGANISATION);
            }
            if (error.response.status === StatusCodeEnum.LOCKED) {
                // Handle 423 status code with the password confirmation modal
                return new Promise(async (resolve, reject) => {
                    const swal = Vue.swal.mixin({
                        title: Vue.prototype.$t('swals.passwordConfirmation.title'),
                        text: Vue.prototype.$t('swals.passwordConfirmation.description').toString(),
                        input: 'password',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger mr-1',
                        },
                        buttonsStyling: false,
                        inputAttributes: {
                            autocapitalize: 'off',
                            autocomplete: 'off',
                        },
                        showCancelButton: true,
                        confirmButtonText: Vue.prototype.$t('common.buttons.submit'),
                        cancelButtonText: Vue.prototype.$t('common.buttons.cancel'),
                        showLoaderOnConfirm: true,
                        reverseButtons: true,
                        preConfirm: async (password) => {
                            const response = await adminApiService.passwordStatusConfirm(password);
                            if (response.isError()) {
                                swal.showValidationMessage(Vue.prototype.$t('messages.errors.passwordConfirmationFailed'));
                            }
                        },
                    });
                    swal.fire().then(async (result) => {
                        if (result.isConfirmed) {
                            const password = result.value;
                            const response = await adminApiService.passwordStatusConfirm(password);
                            if (response.isSuccess()) {
                                // Retry the original request after password confirmation
                                try {
                                    Vue.swal.mixin({
                                        title: Vue.prototype.$t('swals.passwordConfirmed.title'),
                                        text: Vue.prototype.$t('swals.passwordConfirmed.description'),
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 5000,
                                        timerProgressBar: true,
                                    })
                                        .fire();
                                    const originalRequest = error.config;
                                    const retryResponse = await this._axiosInstance(originalRequest);
                                    resolve(retryResponse);
                                }
                                catch (retryError) {
                                    reject(retryError);
                                }
                            }
                            else {
                                // Password confirmation failed, show an error message
                                swal.fire({
                                    icon: 'error',
                                    title: Vue.prototype.$t('messages.errors.passwordConfirmationFailed'),
                                });
                                // eslint-disable-next-line no-use-before-define
                                reject(new ErrorApiOperationResult().withMessage(Vue.prototype.$t('messages.errors.passwordConfirmationRequired')));
                            }
                        }
                        else {
                            reject();
                        }
                    });
                });
            }
            return Promise.reject(error.response);
        });
    }
    static instance(config) {
        if (!HttpClientWrapper._instance || HttpClientWrapper._instance._axiosInstance.defaults.baseURL !== config.baseUrl) {
            HttpClientWrapper._instance = new HttpClientWrapper(config);
        }
        return HttpClientWrapper._instance;
    }
    static defaultInstance(store, config = {}) {
        return HttpClientWrapper
            .instance({
            baseUrl: config.baseUrl,
            language: config.language,
            unauthenticatedHandler: (response) => {
                store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_OUT));
                return Promise.resolve();
            },
        });
    }
    client() {
        if (!HttpClientWrapper._instance) {
            throw new Error('HttpClientWrapper not initialised');
        }
        return HttpClientWrapper._instance._axiosInstance;
    }
}
export class ApiOperationResult {
    constructor(status) {
        this.status = status;
    }
    get data() {
        return this._data;
    }
    set data(value) {
        this._data = value;
    }
    get message() {
        return this._message || '';
    }
    set message(value) {
        this._message = value;
    }
    isSuccess() {
        return this.status == ApiOperationStatusEnum.SUCCESS;
    }
    isError() {
        return this.status == ApiOperationStatusEnum.ERROR;
    }
    isCancel() {
        return this.status == ApiOperationStatusEnum.CANCEL;
    }
    hasMessage() {
        return !!this._message;
    }
    hasData() {
        return !!this._data;
    }
}
class ApiOperationResultBuilder extends ApiOperationResult {
    constructor(status) {
        super(status);
    }
    withMessage(message) {
        this.message = message;
        return this;
    }
    withData(data) {
        this.data = data;
        return this;
    }
}
export class SuccessApiOperationResult extends ApiOperationResultBuilder {
    constructor() {
        super(ApiOperationStatusEnum.SUCCESS);
    }
}
export class ErrorApiOperationResult extends ApiOperationResultBuilder {
    constructor() {
        super(ApiOperationStatusEnum.ERROR);
    }
}
export class EmptyApiOperationResult extends ApiOperationResultBuilder {
    constructor() {
        super(ApiOperationStatusEnum.SUCCESS);
    }
}
export class CancelApiOperationResult extends ApiOperationResultBuilder {
    constructor() {
        super(ApiOperationStatusEnum.CANCEL);
    }
}
export class DefaultErrorHandler {
    static handleError(errorResponse) {
        // this is handling cancelled requests
        if (!errorResponse.status)
            return Promise.resolve(new CancelApiOperationResult());
        if (axios.isCancel(errorResponse)) {
            return Promise.resolve(new CancelApiOperationResult());
        }
        if (`${process.env}` !== 'production')
            console.error(errorResponse);
        switch (errorResponse.status) {
            case StatusCodeEnum.UNAUTHORIZED:
                return Promise.resolve(new ErrorApiOperationResult());
            case StatusCodeEnum.UNPROCESSABLE_CONTENT:
                return Promise.resolve(new ErrorApiOperationResult()
                    .withMessage(errorResponse.data.message)
                    .withData(errorResponse));
            case StatusCodeEnum.FORBIDDEN:
                return Promise.resolve(new ErrorApiOperationResult()
                    .withMessage(Vue.prototype.$t('messages.errors.dontHavePermission')));
            case StatusCodeEnum.LOCKED:
                return Promise.resolve(new ErrorApiOperationResult()
                    .withMessage(Vue.prototype.$t('messages.errors.passwordConfirmationRequired')));
            default:
                return Promise.resolve(new ErrorApiOperationResult()
                    .withMessage(Vue.prototype.$t('messages.errors.unknownError'))
                    .withData(errorResponse));
        }
    }
}
