var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import { LocaleEnum } from '@vini-wine/core-enums';
import { identifier } from '@/service/api/core/enums/timezone';
import store from '@/store';
import { getDateFormat, getDateTimeFormat } from '@/service/api/core/enums/dateTimeFormat';
import 'moment/min/locales';
// eslint-disable-next-line no-use-before-define
let DatesAndTimesMixin = class DatesAndTimesMixin extends Vue {
    // eslint-disable-next-line class-methods-use-this
    humanizeDate(date, locale) {
        let convertedLocale = 'en-gb';
        switch (locale) {
            // these locales have the same locale code as the alpha 2
            case LocaleEnum.DUTCH:
            case LocaleEnum.FRENCH:
            case LocaleEnum.ITALIAN:
            case LocaleEnum.PORTUGUESE:
            case LocaleEnum.RUSSIAN:
            case LocaleEnum.SPANISH:
                convertedLocale = locale;
                break;
            // these locales have additional suffix, that's why they need to be adapted
            case LocaleEnum.CHINESE:
                convertedLocale = 'zh-cn';
                break;
            case LocaleEnum.ENGLISH:
            default:
                convertedLocale = 'en-gb';
                break;
        }
        moment.locale(convertedLocale);
        return moment(date).fromNow();
    }
    // eslint-disable-next-line class-methods-use-this
    formatDate(date, customTimezone) {
        const ddtf = store.state.admin.user.defaultDateTimeFormat;
        let timezone = store.state.admin.user.defaultTimezone;
        if (customTimezone) {
            timezone = customTimezone;
        }
        const utcFormatted = moment.utc(date, getDateFormat(ddtf));
        const userTimezoneFormatted = utcFormatted.clone().tz(identifier(timezone));
        return userTimezoneFormatted.format(getDateFormat(ddtf));
    }
    // eslint-disable-next-line class-methods-use-this
    formatDateTime(date, customTimezone) {
        const ddtf = store.state.admin.user.defaultDateTimeFormat;
        let timezone = store.state.admin.user.defaultTimezone;
        if (customTimezone) {
            timezone = customTimezone;
        }
        const utcFormatted = moment.utc(date, getDateTimeFormat(ddtf));
        const userTimezoneFormatted = utcFormatted.clone().tz(identifier(timezone));
        return userTimezoneFormatted.format(getDateTimeFormat(ddtf));
    }
};
DatesAndTimesMixin = __decorate([
    Component({
        computed: {
            formattedDateTime() {
                return (date, customTimezone) => this.formatDateTime(date, customTimezone);
            },
            formattedDate() {
                return (date, customTimezone) => this.formatDate(date, customTimezone);
            },
        },
    })
], DatesAndTimesMixin);
export default DatesAndTimesMixin;
