import Pusher from 'pusher-js';
const PusherBatchAuthorizer = require('pusher-js-auth');
// @ts-ignore
const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    channelAuthorization: {
        transport: 'jsonp',
        endpoint: `${process.env.VUE_APP_API_URL}/api/admin-api/v1/broadcasting/auth`,
    },
    authorizer: PusherBatchAuthorizer,
});
export default pusher;
