var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Add, AlarmBell, AlertMessageLaptop, AlertTriangle, ArrowLeft, ArrowRight, AwardTrophyStar1, Bin2, ButtonRecord1, Calendar3, CashPaymentBag, ChateauFrontenacCanada, CloudAdd, CloudCheck, CloudDownload, CloudSearch, CloudText, CloudUpload, Cog1, DiagramUpLargeHead, DiamondGive, DoNotDisturbSleepMode, EyeIdea, GraphStatsDescend, House1, InformationCircle, InformationDeskCustomer, ListAdd, LoginKey, Logout1, MapsPin, MoodRock, MultipleNeutral1, MultipleNeutral2, NavigationMenuHorizontal, RankingWinnerRibbon, RemoveCircle, SavingPiggyDollars, SchoolInvitationCard1, Search, SeoSearch, ShapeSquareAdd, Shop, SignBadgeCircle, SingleNeutralActionsText, SmallOfficeDoubleBuilding, SubscriptionHandClick2, SupplyChainSupplierTrolleyDelivery, SymbolEqual, TargetCenter, TimeTwentourHours1, TradingBuy, TradingMonitor, UploadBottom, WeatherSun, WineGlassBottle, WineGrapes, WorkFromHomeUserSit, RotateFront, TagsSettings, HeartsCard, RatingStar, WineBarrel1, CloudSync, CloudClock, CodingAppsWebsiteBigDataSyncCloud, BusinessCard1, EmailActionReply1, EmailActionSend2, CloudCash, CloudQuestion, GamingRibbonFirst, SynchroniseRefreshArrow2, Expand3, Hyperlink, RequestFiles, AccountingInvoiceHand, AccountingInvoiceMail, ShippingTruckFast, ArrowButtonDown2, } from '@vini-wine/core-icons';
// eslint-disable-next-line no-use-before-define
let UltimateIcon = class UltimateIcon extends mixins() {
    selectedIcon() {
        switch (this.icon) {
            case 'Add':
                return Add(this.size, this.color);
            case 'AlarmBell':
                return AlarmBell(this.size, this.color);
            case 'AlertMessageLaptop':
                return AlertMessageLaptop(this.size, this.color);
            case 'AlertTriangle':
                return AlertTriangle(this.size, this.color);
            case 'ArrowLeft':
                return ArrowLeft(this.size, this.color);
            case 'ArrowRight':
                return ArrowRight(this.size, this.color);
            case 'AwardTrophyStar1':
                return AwardTrophyStar1(this.size, this.color);
            case 'Bin2':
                return Bin2(this.size, this.color);
            case 'ButtonRecord1':
                return ButtonRecord1(this.size, this.color);
            case 'Calendar3':
                return Calendar3(this.size, this.color);
            case 'CashPaymentBag':
                return CashPaymentBag(this.size, this.color);
            case 'ChateauFrontenacCanada':
                return ChateauFrontenacCanada(this.size, this.color);
            case 'CloudAdd':
                return CloudAdd(this.size, this.color);
            case 'CloudCheck':
                return CloudCheck(this.size, this.color);
            case 'CloudDownload':
                return CloudDownload(this.size, this.color);
            case 'CloudSearch':
                return CloudSearch(this.size, this.color);
            case 'CloudText':
                return CloudText(this.size, this.color);
            case 'CloudUpload':
                return CloudUpload(this.size, this.color);
            case 'Cog1':
                return Cog1(this.size, this.color);
            case 'DiagramUpLargeHead':
                return DiagramUpLargeHead(this.size, this.color);
            case 'DiamondGive':
                return DiamondGive(this.size, this.color);
            case 'DoNotDisturbSleepMode':
                return DoNotDisturbSleepMode(this.size, this.color);
            case 'EyeIdea':
                return EyeIdea(this.size, this.color);
            case 'GraphStatsDescend':
                return GraphStatsDescend(this.size, this.color);
            case 'House1':
                return House1(this.size, this.color);
            case 'InformationCircle':
                return InformationCircle(this.size, this.color);
            case 'InformationDeskCustomer':
                return InformationDeskCustomer(this.size, this.color);
            case 'ListAdd':
                return ListAdd(this.size, this.color);
            case 'LoginKey':
                return LoginKey(this.size, this.color);
            case 'MapsPin':
                return MapsPin(this.size, this.color);
            case 'MoodRock':
                return MoodRock(this.size, this.color);
            case 'MultipleNeutral1':
                return MultipleNeutral1(this.size, this.color);
            case 'MultipleNeutral2':
                return MultipleNeutral2(this.size, this.color);
            case 'NavigationMenuHorizontal':
                return NavigationMenuHorizontal(this.size, this.color);
            case 'RankingWinnerRibbon':
                return RankingWinnerRibbon(this.size, this.color);
            case 'RemoveCircle':
                return RemoveCircle(this.size, this.color);
            case 'SavingPiggyDollars':
                return SavingPiggyDollars(this.size, this.color);
            case 'SchoolInvitationCard1':
                return SchoolInvitationCard1(this.size, this.color);
            case 'Search':
                return Search(this.size, this.color);
            case 'SeoSearch':
                return SeoSearch(this.size, this.color);
            case 'ShapeSquareAdd':
                return ShapeSquareAdd(this.size, this.color);
            case 'Shop':
                return Shop(this.size, this.color);
            case 'SignBadgeCircle':
                return SignBadgeCircle(this.size, this.color);
            case 'SingleNeutralActionsText':
                return SingleNeutralActionsText(this.size, this.color);
            case 'SmallOfficeDoubleBuilding':
                return SmallOfficeDoubleBuilding(this.size, this.color);
            case 'SubscriptionHandClick2':
                return SubscriptionHandClick2(this.size, this.color);
            case 'SupplyChainSupplierTrolleyDelivery':
                return SupplyChainSupplierTrolleyDelivery(this.size, this.color);
            case 'SymbolEqual':
                return SymbolEqual(this.size, this.color);
            case 'TagsSettings':
                return TagsSettings(this.size, this.color);
            case 'TargetCenter':
                return TargetCenter(this.size, this.color);
            case 'TimeTwentourHours1':
                return TimeTwentourHours1(this.size, this.color);
            case 'TradingBuy':
                return TradingBuy(this.size, this.color);
            case 'TradingMonitor':
                return TradingMonitor(this.size, this.color);
            case 'UploadBottom':
                return UploadBottom(this.size, this.color);
            case 'WeatherSun':
                return WeatherSun(this.size, this.color);
            case 'WineGlassBottle':
                return WineGlassBottle(this.size, this.color);
            case 'WineGrapes':
                return WineGrapes(this.size, this.color);
            case 'WorkFromHomeUserSit':
                return WorkFromHomeUserSit(this.size, this.color);
            case 'Logout1':
                return Logout1(this.size, this.color);
            case 'RotateFront':
                return RotateFront(this.size, this.color);
            case 'HeartsCard':
                return HeartsCard(this.size, this.color);
            case 'RatingStar':
                return RatingStar(this.size, this.color);
            case 'WineBarrel1':
                return WineBarrel1(this.size, this.color);
            case 'CloudSync':
                return CloudSync(this.size, this.color);
            case 'CloudClock':
                return CloudClock(this.size, this.color);
            case 'CodingAppsWebsiteBigDataSyncCloud':
                return CodingAppsWebsiteBigDataSyncCloud(this.size, this.color);
            case 'BusinessCard1':
                return BusinessCard1(this.size, this.color);
            case 'EmailActionReply1':
                return EmailActionReply1(this.size, this.color);
            case 'EmailActionSend2':
                return EmailActionSend2(this.size, this.color);
            case 'CloudCash':
                return CloudCash(this.size, this.color);
            case 'CloudQuestion':
                return CloudQuestion(this.size, this.color);
            case 'GamingRibbonFirst':
                return GamingRibbonFirst(this.size, this.color);
            case 'SynchroniseRefreshArrow2':
                return SynchroniseRefreshArrow2(this.size, this.color);
            case 'Expand3':
                return Expand3(this.size, this.color);
            case 'Hyperlink':
                return Hyperlink(this.size, this.color);
            case 'RequestFiles':
                return RequestFiles(this.size, this.color);
            case 'AccountingInvoiceHand':
                return AccountingInvoiceHand(this.size, this.color);
            case 'AccountingInvoiceMail':
                return AccountingInvoiceMail(this.size, this.color);
            case 'ShippingTruckFast':
                return ShippingTruckFast(this.size, this.color);
            case 'ArrowButtonDown2':
                return ArrowButtonDown2(this.size, this.color, this.fill);
            default:
                return 'Unknown icon';
        }
    }
};
__decorate([
    Prop({ default: '24' })
], UltimateIcon.prototype, "size", void 0);
__decorate([
    Prop({ default: 'currentColor' })
], UltimateIcon.prototype, "color", void 0);
__decorate([
    Prop({ default: 'none' })
], UltimateIcon.prototype, "fill", void 0);
__decorate([
    Prop({ default: '' })
], UltimateIcon.prototype, "icon", void 0);
__decorate([
    Prop({ default: null })
], UltimateIcon.prototype, "badge", void 0);
__decorate([
    Prop({ default: 'badge-primary' })
], UltimateIcon.prototype, "badgeClasses", void 0);
UltimateIcon = __decorate([
    Component({})
], UltimateIcon);
export default UltimateIcon;
