import Vue from 'vue';
import realtime from '@/realtime/realtime';
// eslint-disable-next-line no-shadow,no-unused-vars
export var ChannelType;
(function (ChannelType) {
    // eslint-disable-next-line no-unused-vars
    ChannelType["PUBLIC"] = "public";
    // eslint-disable-next-line no-unused-vars
    ChannelType["PRIVATE"] = "private";
    // eslint-disable-next-line no-unused-vars
    ChannelType["PRIVATE_ENCRYPTED"] = "private_encrypted";
    // eslint-disable-next-line no-unused-vars
    ChannelType["PRESENCE"] = "presence";
    // eslint-disable-next-line no-unused-vars
    ChannelType["CACHE"] = "cache";
    // eslint-disable-next-line no-unused-vars
    ChannelType["PRIVATE_CACHE"] = "private_cache";
})(ChannelType || (ChannelType = {}));
const channelTypePrefix = (type) => {
    switch (type) {
        case ChannelType.PRIVATE:
            return 'private-';
        case ChannelType.CACHE:
            return 'cache-';
        case ChannelType.PRIVATE_ENCRYPTED:
            return 'private-encrypted-';
        case ChannelType.PRESENCE:
            return 'presence-';
        case ChannelType.PRIVATE_CACHE:
            return 'private-cache-';
        default:
            return '';
    }
};
// eslint-disable-next-line import/prefer-default-export
export class BaseChannel extends Vue {
    constructor(type) {
        super();
        this.channel = null;
        this.type = type;
        this.suffix = null;
    }
    setSuffix(suffix) {
        this.suffix = suffix;
    }
    channelName() {
        return `${channelTypePrefix(this.type)}${this.suffix}`;
    }
    subscribe() {
        try {
            this.channel = realtime.subscribe(this.channelName());
        }
        catch (error) {
            console.error(error);
        }
    }
    bind(event, callback) {
        return this.channel?.bind(event, callback);
    }
    unsubscribe() {
        realtime.unsubscribe(this.channelName());
    }
}
