import moment from 'moment';
import { ns, ROOT_STATE_MUTATIONS } from "@/store";
import { ADMIN_STATE_MUTATIONS, AdminStore } from "@/store/admin";
const LOCAL_STORAGE_ADMIN_KEY = 'admin-store';
const LOCAL_STORAGE_ADMIN_TIMESTAMP = 'admin-store-timestamp';
export const isLocalStorageStoreValid = () => {
    if (!localStorage.getItem(LOCAL_STORAGE_ADMIN_KEY) || !(localStorage.getItem(LOCAL_STORAGE_ADMIN_TIMESTAMP)))
        return false;
    let store = localStorage.getItem(LOCAL_STORAGE_ADMIN_KEY);
    if (store && !JSON.parse(store).admin.user.loggedIn)
        return false;
    // Check if app was inactive for more than 15min
    return moment().isAfter(moment(Number(localStorage.getItem(LOCAL_STORAGE_ADMIN_TIMESTAMP)))
        .add('minutes', 15));
};
export const getLocalStorageStateAsJson = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_ADMIN_KEY));
export const bindLocalStorage = (store) => {
    store.subscribe((mutation, state) => {
        if (mutation.type !== ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_OUT)) {
            localStorage.setItem(LOCAL_STORAGE_ADMIN_KEY, JSON.stringify(state));
            localStorage.setItem(LOCAL_STORAGE_ADMIN_TIMESTAMP, moment().unix() + "");
        }
        else {
            localStorage.removeItem(LOCAL_STORAGE_ADMIN_KEY);
            localStorage.removeItem(LOCAL_STORAGE_ADMIN_TIMESTAMP);
        }
        if (mutation.type === ns(AdminStore.NS, ROOT_STATE_MUTATIONS.MARK_ACTIVE)) {
            localStorage.setItem(LOCAL_STORAGE_ADMIN_TIMESTAMP, moment().unix() + "");
        }
    });
};
