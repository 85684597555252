export const PRESENCE_STATE_MUTATIONS = {
    SET_USER: 'SET_USER',
    REMOVE_USER: 'REMOVE_USER',
    CLEAR_USERS: 'CLEAR_USERS',
};
export const PRESENCE_STATE_GETTERS = {
    GET_USERS: 'GET_USERS',
};
const INITIAL_USERS_STATE = {
    presentUsers: [],
};
const initState = () => JSON.parse(JSON.stringify(INITIAL_USERS_STATE));
export class PresenceStore {
    constructor() {
        this.namespaced = true;
        this.state = initState();
        this.getters = {
            [PRESENCE_STATE_GETTERS.GET_USERS]: (state) => state.presentUsers,
        };
        this.mutations = {
            [PRESENCE_STATE_MUTATIONS.SET_USER](state, user) {
                state.presentUsers.push(user);
            },
            [PRESENCE_STATE_MUTATIONS.REMOVE_USER](state, user) {
                const index = state.presentUsers.indexOf(user);
                state.presentUsers.splice(index, 1);
            },
            [PRESENCE_STATE_MUTATIONS.CLEAR_USERS](state) {
                state.presentUsers = [];
            },
        };
        this.actions = {};
    }
}
PresenceStore.NS = 'presence';
