var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component, { mixins } from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { ns } from '@/store';
import { ADMIN_STATE_GETTERS, AdminStore } from '@/store/admin';
import momentTZ from 'moment-timezone';
import { Inject } from 'vue-property-decorator';
import Toast from '@/mixin/toast';
import { convertFromIdentifierToEnumValue, identifier } from '@/service/api/core/enums/timezone';
import DatesAndTimesMixin from '@/mixin/datesAndTimes';
// eslint-disable-next-line no-use-before-define
let ChangeTimezoneModal = class ChangeTimezoneModal extends mixins(Toast, DatesAndTimesMixin) {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.currentBrowserTimezone = convertFromIdentifierToEnumValue(momentTZ.tz.guess());
        // @ts-ignore
        this.currentBrowserTime = this.formatDateTime(momentTZ(), this.currentBrowserTimezone);
        this.accountDefaultTimezone = this.$store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.DEFAULT_TIMEZONE)];
        // @ts-ignore
        this.accountDefaultTime = this.formatDateTime(momentTZ(), this.accountDefaultTimezone);
        this.form = {
            firstName: this.$store.state.admin.user.firstName,
            lastName: this.$store.state.admin.user.lastName,
            email: this.$store.state.admin.user.email,
            preferredLanguage: this.$store.state.admin.user.preferredLanguage,
            preferredCurrency: this.$store.state.admin.user.preferredCurrency,
            defaultDateTimeFormat: this.$store.state.admin.user.defaultDateTimeFormat,
            defaultTimezone: this.currentBrowserTimezone,
            avatar: this.$store.state.admin.user.avatarUrl ? this.$store.state.admin.user.avatarUrl : '',
        };
    }
    async showModal() {
        this.$root.$emit('bv::show::modal', 'change-timezone-modal', '#btnShow');
    }
    openInNewTab() {
        const routeData = this.$router.resolve({ name: 'account-settings' });
        window.open(routeData.href, '_blank');
    }
    async changeTimezoneFromBrowser() {
        this.isLoading = true;
        const response = await this.adminApi.updateAccount(this.form);
        this.isLoading = false;
        if (response.isSuccess()) {
            this.successToast(this.$t('messages.success.timezoneUpdatedSuccessfully').toString());
            this.$root.$emit('bv::hide::modal', 'change-timezone-modal', '#btnShow');
        }
        else {
            this.errorToast(response.message);
        }
    }
};
__decorate([
    Inject('adminApi')
], ChangeTimezoneModal.prototype, "adminApi", void 0);
ChangeTimezoneModal = __decorate([
    Component({
        methods: { identifier, momentTZ },
        components: {
            BModal,
        },
    })
], ChangeTimezoneModal);
export default ChangeTimezoneModal;
