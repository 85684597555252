// eslint-disable-next-line import/no-cycle
import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/offered-vintage-products',
        name: 'offers-list',
        component: () => import('@/views/organisation/offers/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.OFFER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.OFFER__VIEW),
            pageTitle: 'pageTitles.offers.list',
            disableScrollBehaviour: true,
            breadcrumb: [
                {
                    text: 'breadcrumbs.offersList.offers',
                },
                {
                    text: 'breadcrumbs.offersList.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/offered-vintage-products/:vintageProductUuid/offers',
        name: 'vintage-product-offers-modal',
        component: () => import('@/views/organisation/offers/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.OFFER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.OFFER__VIEW),
            pageTitle: 'pageTitles.offers.list',
            disableScrollBehaviour: true,
            breadcrumb: [
                {
                    text: 'breadcrumbs.offersList.offers',
                },
                {
                    text: 'breadcrumbs.offersList.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
