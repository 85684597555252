// eslint-disable-next-line import/prefer-default-export
export class BaseApiService {
    constructor() {
        // @ts-ignore
        this.cancelTokens = {};
    }
    cancelRequest(functionName) {
        if (this.cancelTokens[functionName]) {
            this.cancelTokens[functionName].cancel(`Request for ${functionName} canceled by the user`);
        }
    }
}
