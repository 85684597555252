import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
import { handleOrganisationRoutes } from '@/helpers/router';
export default [
    {
        path: '/organisation/:orgUuid/email-parser',
        name: 'organisation-email-parser-list',
        component: () => import('@/views/organisation/email-parser/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.INBOUND_EMAIL_VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.INBOUND_EMAIL_VIEW),
            pageTitle: 'pageTitles.emailParser.list',
            goBackRoute: 'home',
            breadcrumb: [
                {
                    text: 'breadcrumbs.emailParser.list.emailParser',
                },
                {
                    text: 'breadcrumbs.emailParser.list.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
