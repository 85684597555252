import Vue from 'vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'

// Service providers
import providers from '@/service'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

import { bindLocalStorage } from '@/helpers/local-storage'
import { environment, isLocalEnvironment, isProductionEnvironment } from '@/helpers/environment'
import { bindRouter } from '@/helpers/router'
import { bindAbilities } from '@/helpers/auth'
import { ADMIN_STATE_GETTERS, AdminStore } from '@/store/admin'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// eslint-disable-next-line import/no-absolute-path,import/no-unresolved
import '/node_modules/flag-icons/css/flag-icons.min.css'

// Rollbar
import Rollbar from 'rollbar'
import { Cropper } from 'vue-advanced-cropper'
import App from './App.vue'
import store, { ns, ROOT_STATE_MUTATIONS } from './store'
import router from './router'

import 'vue-advanced-cropper/dist/style.css'
import { PresenceAppChannel } from '@/realtime/channels/presence/presenceAppChannel'

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Cropper', Cropper)

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput)
Vue.use(VueCookies, { expires: '1000d', domain: process.env.VUE_APP_COOKIE_DOMAIN })

Vue.prototype.$t = i18n.t.bind(i18n)

Vue.prototype.$appPresenceChannel = new PresenceAppChannel()

if (!isLocalEnvironment()) {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: '38ccc4589def4f20a802717761467cf3',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    payload: {
      // Track your events to a specific version of code for better visibility into version health
      code_version: '1.0.0',
      environment,
      // Add custom data to your events by adding custom key/value pairs like the one below
      // custom_data: 'foo'
    },
  })

  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err)
    throw err // rethrow
  }
}

if (isProductionEnvironment()) {
  Vue.use(VueGtag, {
    config: { id: 'G-7V1C4HHHVB' },
  })
}

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueMeta)

// Composition API
Vue.use(VueCompositionAPI)

// form-wizard icons
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

store.commit(ROOT_STATE_MUTATIONS.INITIALIZE_STORE)
bindLocalStorage(store)
bindRouter(store, router)
bindAbilities(store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.USER)].abilities)

new Vue({
  router,
  store,
  i18n,
  provide: () => providers(store),
  render: h => h(App),
}).$mount('#app')
